<template>
  <NavBar />
  <div class="flex-main overflow-hidden position-relative" ref="container">
    <BetterScroll
      class="content position-relative"
      :probeType="3"
      :contentIndex="2"
      ref="scrollEl"
      @scroll="scrollHandler"
      enablePullDown
      :pullingDownHandler="pullDownHandler"
    >
      <SortBar ref="titleRef" class="position-relative" v-model="sortType" :options="options" @change="sortChange">
        <div class="flex">
          <span class="line-22">库存总量</span>
          &nbsp;
          <span class="font-18 green">{{ totalInventory }}</span>
        </div>
      </SortBar>
      <div class="pulldown-wrapper position-absolute">
        <div v-show="beforePullDown">
          <span>下拉刷新</span>
        </div>
        <div v-show="!beforePullDown">
          <div v-show="isPullingDown">
            <span>加载中...</span>
          </div>
          <div v-show="!isPullingDown">
            <span>{{ isRequestErr ? '请求失败' : '刷新完成' }}</span>
          </div>
        </div>
      </div>

      <div style="background-color: #fff">
        <div class="grid title">
          <div>厂区名称</div>
          <div ref="widthEl" class="blue text-center">安装</div>
          <div class="green text-center">库存</div>
          <van-popover v-model:show="showPopover" theme="dark" placement="bottom-end" @open="autoClose">
            <div class="tips">厂区最后一次安装设备距今的时间(单位: 天)</div>
            <template #reference>
              <div class="flex justify-center">
                空窗期
                <van-image class="tip-icon" :src="TipPic" />
              </div>
            </template>
          </van-popover>
        </div>
        <div class="explanation" v-if="companies.length">
          <van-image :src="shareImg" class="share-icon" />
          <span class="notice">共享库存，点击可查看相关共享厂区</span>
        </div>
        <Company
          class="line"
          v-for="(item, index) in companies"
          :key="item.id"
          :type="sortType"
          v-bind="item"
          :rank="index"
        />
        <div v-show="companies.length" class="text-center padding-8" style="color: #dcdee0">没有更多数据了</div>
      </div>
      <!-- 空提示 -->
      <van-empty v-if="!reqLoading && !companies.length" image="search" description="未查询到相关数据" />
    </BetterScroll>

    <!-- 滚动后固定在头部的内容 -->
    <div class="grid title position-absolute fixed-top w-100" v-show="showTop">
      <div>厂区名称</div>
      <div class="blue text-center">安装</div>
      <div class="green text-center">库存</div>
      <van-popover v-model:show="showFakePopover" theme="dark" placement="bottom-end" @open="autoFakeClose">
        <div class="tips">厂区最后一次安装设备距今的时间(单位: 天)</div>
        <template #reference>
          <div class="flex justify-center">
            空窗期
            <van-image class="tip-icon" :src="TipPic" />
          </div>
        </template>
      </van-popover>
    </div>
    <div @click="backToTop" class="affix" v-if="showTop">
      <van-icon name="arrow-up" />
    </div>
  </div>
</template>

<script>
/**
 * 厂区库存
 * author welkin
 */
import { ref, shallowRef, watch, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Popover } from 'vant'
import http from '@/utils/request'

import TipPic from '@/assets/home/tips.png'
import shareImg from '@/assets/install/share.png'

import { usePullDown } from '../common/hooks'

import SortBar from '../components/sort-bar.vue'
import Company from './components/company.vue'

import BetterScroll from '@/components/scroll/scroll.vue'
// 排序筛选类型
const options = [
  { text: '库存总量', value: 1 },
  { text: '安装总量', value: 2 }
]

export default {
  name: 'Inventory',
  components: {
    SortBar,
    Company,
    BetterScroll,
    [Popover.name]: Popover
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const menus = computed(() => store.state.permission.menus) // 获取用户权限
    const auth_inventory = computed(() => menus.value.find(el => el.sourceKey === 'desktop:inventory'))
    if (!auth_inventory.value) router.replace({ name: 'Home' })

    // 顶部切换类型组件 dom
    const titleRef = shallowRef()

    const sortType = ref(1)
    const totalInventory = ref(0) // 库存总量

    const scrollEl = shallowRef()

    const showPopover = ref(false)

    const widthEl = shallowRef()

    const companies = ref([])

    // 保存顶部距离，超出后需要隐藏
    let hiddenDiff = 0
    watch(titleRef, val => {
      const el = val.$el
      if (el) {
        hiddenDiff = el.offsetHeight
      }
    })
    // 是否展示顶部栏
    const showTop = ref(false)
    // 保存上次滚动位置，判断是否从下滚动

    const scrollHandler = position => {
      const distance = -position.y
      // // 滚动切换布局，自动隐藏顶部
      if (distance >= hiddenDiff) {
        showTop.value = true
      } else {
        showTop.value = false
      }
    }
    const container = ref()
    // 计算安装，库存，空窗期对应的宽度
    watch(widthEl, el => {
      const width = el.clientWidth
      if (container.value) {
        container.value.style.setProperty('--common-width', width + 'px')
      }
    })

    const backToTop = () => {
      scrollEl.value.scrollTo(0, 0)
    }
    // 切换查询类型，重新请求接口
    const sortChange = type => {
      sortType.value = type
      refreshData()
    }
    // 空窗期提示自动关闭
    const autoClose = () => {
      setTimeout(() => {
        showPopover.value = false
      }, 3000)
    }

    const showFakePopover = ref(false)
    // 空窗期提示自动关闭
    const autoFakeClose = () => {
      setTimeout(() => {
        showFakePopover.value = false
      }, 3000)
    }
    const reqLoading = ref(false)
    // 初始化数据
    refreshData()

    async function refreshData() {
      if (reqLoading.value) return
      reqLoading.value = true
      try {
        const { data } = await http.get('company/companyInventoryForH5.html', { params: { sortType: sortType.value } })
        const { totalInventory: total, list } = data
        totalInventory.value = total || 0
        companies.value = toTree(list)
      } finally {
        reqLoading.value = false
      }
    }

    const { beforePullDown, isPullingDown, isRequestErr, pullDownHandler } = usePullDown(refreshData)

    return {
      container,
      TipPic,
      shareImg,
      options,
      titleRef,
      sortType,
      totalInventory,
      scrollEl,
      showPopover,
      showFakePopover,
      widthEl,
      companies,
      showTop,
      // isLoading,
      scrollHandler,
      backToTop,
      sortChange,
      autoClose,
      autoFakeClose,
      reqLoading,
      beforePullDown,
      isPullingDown,
      isRequestErr,
      pullDownHandler
    }
  }
}

// 平级转树形
function toTree(arr) {
  const tree = []
  // 公司 map
  const comMap = new Map()

  for (let com of arr) {
    // 可能先遍历到子级时新建父级公司信息
    // 进行判断，写入公司信息
    if (!comMap.has(com.companyId)) {
      comMap.set(com.companyId, { children: [] })
    }
    // 如果是后遍历到父级，此时 map 中已有父级，但是没写入数据
    // 需要获取后写入
    const current = comMap.get(com.companyId)
    current.name = com.companyName
    current.id = com.companyId
    current.parentId = com.parentId
    current.install = com.totalInstall
    current.inventory = com.totalInventory
    current.rest = com.windowPeriod
    current.shareCompany = com.shareCompany
    // 顶级公司写入
    if (!current.parentId) {
      tree.push(current)
      continue
    }
    // 没有父级新建
    if (!comMap.has(com.parentId)) {
      comMap.set(com.parentId, { children: [] })
    }
    const parent = comMap.get(com.parentId)

    parent.children.push(current)
  }
  return tree
}
</script>
<style lang="scss" scoped>
.content {
  height: 100%;
  overflow: hidden;
  font-size: 14px;
  color: #404867;
}

.title {
  padding: 11px 12px;
  align-items: center;
  font-size: 14px;
}
.grid {
  display: grid;
  grid-template-columns: 2.2fr 1fr 1fr 1fr;
}
.font-18 {
  font-size: 18px;
  line-height: 20px;
}

.line-22 {
  line-height: 21px;
}

.justify-center {
  justify-content: center;
}

.tip-icon {
  width: 16px;
  height: 16px;
  margin-left: 3px;
}

.tips {
  font-size: 12px;
  line-height: 1.2;
  padding: 5px 10px;
  border-radius: 4px;
}
.line {
  border-bottom: 1px solid;
  border-image: linear-gradient(0deg, #fff, #f2f8ff 40%, #00000014);
}
.line::after {
  background-color: #fff;
  box-shadow: inset 0 2px 4px #00000014;
}

.affix {
  height: 32px;
  width: 32px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.fixed-top {
  top: 0;
  background-color: #fff;
  box-sizing: border-box;
}
.padding-8 {
  padding: 8px;
}

.explanation {
  padding: 4px 8px;
  .share-icon {
    height: 16px;
    width: 16px;
    vertical-align: middle;
  }
  .notice {
    display: inline-block;
    vertical-align: middle;
    padding-left: 4px;
  }
}

.pulldown-wrapper {
  box-sizing: border-box;
  text-align: center;
  line-height: 32px;
  z-index: -1;
  color: #999;
  width: 100%;
  padding: 6px;
}
</style>
