<template>
  <div class="install flex-spb">
    <div class="install__item position-relative">
      <van-image :src="bgGreen" class="install__image position-absolute" />
      <div class="install__contain">
        <div class="install__item__count" ref="onlineCarNumber">{{ onlineCar }}</div>
        <div class="install__item__title">在线车辆</div>
      </div>
    </div>
    <div class="install__item position-relative">
      <van-image :src="bgGrey" class="install__image position-absolute" />
      <div class="install__contain">
        <div class="install__item__count" ref="offlineCarNumber">{{ offlineCar }}</div>
        <div class="install__item__title">离线车辆</div>
      </div>
    </div>
    <div class="install__item position-relative">
      <van-image :src="bgBlue" class="install__image position-absolute" />
      <div class="install__contain">
        <div class="install__item__count" ref="totalCarNumber">{{ totalCar }}</div>
        <div class="install__item__title">总计车辆</div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import bgGreen from '@/assets/home/home_bg_green.png'
import bgGrey from '@/assets/home/home_bg_grey.png'
import bgBlue from '@/assets/home/home_bg_blue.png'

export default {
  setup() {
    const store = useStore()

    const init = () => {
      store.dispatch('homeOnlineRequest')
    }

    init()

    return {
      bgGreen,
      bgGrey,
      bgBlue,
      onlineCar: computed(() => store.state.homeState.onlineCar), // 车辆在线总数
      offlineCar: computed(() => store.state.homeState.offlineCar), // 车辆离线总数
      totalCar: computed(() => store.getters.totalNumber), // 车辆总数
      init
    }
  }
}
</script>
<style lang="scss" scoped>
.install {
  color: #ffffff;
  margin-top: 16px;
  &__image {
    top: 0;
    left: 0;
    z-index: -1;
    width: 110px;
    height: 77px;
  }
  &__item {
    text-align: center;
    box-sizing: border-box;
    width: 110px;
    height: 77px;
    padding-top: 18px;
    &__count {
      height: 27px;
      font-size: 20px;
      letter-spacing: 1.5px;
      margin-bottom: 3px;
    }
    &__title {
      height: 20px;
      font-size: 12px;
      letter-spacing: 0.9px;
    }
  }
}
</style>
