<template>
  <div class="docker">
    <div
      v-for="(item, index) in dockerList"
      :key="index"
      :class="['docker__item', { docker__item_active: index === currentIndex }]"
      @click="handleDockerClick(index, item)"
    >
      <!-- item.icon 是特殊字符，不能使用模板语法解析，这里使用 v-html  -->
      <div class="icon">
        <van-image class="docker_img" :src="index === currentIndex ? item.activeUrl : item.defaultUrl" />
      </div>
      <div class="docker__title">{{ item.text }}</div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'

import DockerPics from './dockerPics'

export default {
  name: 'Docker',

  setup() {
    const router = useRouter()
    const route = useRoute()

    const store = useStore()
    const menus = computed(() => store.state.permission.menus) // 获取用户权限

    // defaultUrl docker 的默认图标
    // activeUrl 激活状态的 docker 图标
    // text 标题
    // path 对应导航路由
    // auth 对应权限，没有默认不需要权限判断
    const dockerAllList = [
      { defaultUrl: DockerPics.homeIconDefault, activeUrl: DockerPics.homeIconActive, text: '首页', path: 'Home' },
      {
        defaultUrl: DockerPics.locationIconDefault,
        activeUrl: DockerPics.locationIconActive,
        text: '定位',
        path: 'Orientation'
      },
      {
        defaultUrl: DockerPics.searchIconDefault,
        activeUrl: DockerPics.searchIconActive,
        text: '搜车',
        path: 'SearchCar',
        auth: 'desktop:h5:search-car' // 需要权限控制，有权限才展示
      },
      {
        defaultUrl: DockerPics.settingIconDefault,
        activeUrl: DockerPics.settingIconActive,
        text: '设置',
        path: 'Settings',
        auth: 'desktop:h5:setting' // 需要权限控制，有权限才展示
      },
      { defaultUrl: DockerPics.userIconDefault, activeUrl: DockerPics.userIconActive, text: '我的', path: 'User' }
    ]

    const dockerList = computed(() =>
      dockerAllList.filter(item => {
        if (!item.auth) return true
        const auth = item.auth // 需要权限的就判断
        return menus.value.find(el => el.sourceKey === auth)
      })
    )

    const currentIndex = computed(() => {
      let activeIndex = route.query.activeIndex || 0
      return +activeIndex
    })

    function handleDockerClick(index, item) {
      let path = item.path
      router.replace({
        name: path,
        query: {
          activeIndex: index
        }
      })
    }
    return {
      dockerList,
      currentIndex,
      handleDockerClick
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/variables.scss';

.docker {
  display: flex;
  height: 50px;
  border-top: 1px solid $background-color;
  color: $font-color;
  z-index: 200;
  background-color: #fff;
  &__item {
    flex: 1;
    text-align: center;
    width: 48px;
    height: 42px;
    box-sizing: border-box;
    .icon {
      height: 24px;
      width: 24px;
      margin: 8px auto 0;
    }
    .docker_img {
      display: block;
    }
    &_active {
      color: $blue;
    }
  }

  &__title {
    font-size: 20px;
    // 浏览器默认展示的最小像素是12像素，要显示10像素，横向和纵向都缩50%
    transform: scale(0.5, 0.5);
    // 要设置缩放的中心点，这里是以中间和顶部为中心才是合理的位置
    transform-origin: center top;
    height: 14px;
    margin: 2px auto 0;
  }
}
</style>
