<template>
  <div class="header">
    <div ref="titleTab" class="tabs">
      <div class="tab" :style="{ width: titleWidth + 'px' }">
        <div
          v-for="(item, index) in titleDate"
          :key="index"
          :class="['date-title', { active: index + 1 == currentIndex }]"
          @click="clickHandler(index + 1, item)"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
// import { isLeapYear } from '../utils/index'
export default {
  props: {
    initIndex: {
      type: Number,
      default: 0
    },
    titleDate: {
      type: Array,
      required: true
    }
  },
  emits: ['select'],
  setup(props, { emit }) {
    // 当前选中的 index
    const currentIndex = ref(-1)

    const titleTab = ref(null)

    // 每个标题的宽度
    const titleWidth = ref(0)

    onMounted(() => {
      // 初始化写死 tabs 区域整体宽度滚动
      titleTab.value.style.width = document.body.clientWidth + 'px'
      clickHandler(props.initIndex, props.titleDate[props.initIndex - 1])
    })

    const clickHandler = (index, data) => {
      emit('select', index, data)
      if (currentIndex.value === index) return
      currentIndex.value = index
      const clientW = document.body.clientWidth
      const navDiv = titleTab.value.querySelector('.date-title')
      const divW = navDiv.clientWidth
      const measure = (index - 1) * divW + divW / 2
      const offset = clientW / 2 < measure ? measure - clientW / 2 : 0
      titleTab.value.scroll({
        top: 0,
        left: offset,
        behavior: 'smooth'
      })
    }

    return {
      currentIndex,
      titleTab,
      titleWidth,
      clickHandler
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/style/variables.scss';
.header {
  padding-top: 6px;
  height: 30px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 100;
  .tabs {
    height: 34px;
    overflow: scroll;
    transition-duration: 500ms;
    .tab {
      display: flex;
      transition-duration: 500ms;
      .date-title {
        width: 100px;
        font-size: 16px;
        flex-shrink: 0;
        color: #777c90;
        font-weight: 400;
        text-align: center;
      }
      .active {
        position: relative;
        font-weight: 600;
        color: $blue;
      }
      .active::after {
        content: '';
        height: 4px;
        background-color: #1c74f2;
        width: 26px;
        position: absolute;
        left: 50%;
        bottom: -6px;
        transform: translateX(-50%) skew(-30deg, 0deg);
        border-radius: 2px;
      }
    }
  }
}
</style>
