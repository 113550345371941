<template>
  <div class="month w-100">
    <div class="header flex-spb">
      <div @click="decreaseYear" class="header__action">
        <van-image width="26" height="26" :src="prevImg" />
      </div>
      <div class="header__text">{{ currentYear + '年' }}</div>
      <div @click="increaseYear" class="header__action">
        <van-image
          width="26"
          height="26"
          :src="increaseActive ? prevImg : nextImgDisable"
          :class="{ rotated: increaseActive }"
        />
      </div>
    </div>
    <div class="content">
      <div
        class="grid position-relative border-box text-center"
        :class="{ 'in-active': item.description.count === 0 }"
        v-for="(item, index) in fillMonth"
        :key="item.month"
        @click="selectMonth(index)"
      >
        <!-- <div class="date">{{ item.month }}</div> -->
        <div class="date">
          {{ item.month }}
        </div>
        <div class="count">
          {{ item.description.count }}
        </div>

        <div class="pre-count">
          {{ item.description.preCount }}
        </div>
      </div>
    </div>
    <div class="mask position-fixed flex-center" v-if="isLoading">
      <van-loading type="spinner" color="#c8c9cc" vertical>加载中...</van-loading>
    </div>
  </div>
</template>
<script>
import { ref, reactive, onMounted, toRefs, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { http_clean } from '@/utils/request'
import { to } from '@/utils/common'
import prevImg from '@/assets/calendar/caldendar_prev.png'
import nextImgDisable from '@/assets/calendar/caldendar_next_disable.png'
export default {
  name: 'MonthSummary',
  setup() {
    const router = useRouter()
    const route = useRoute()

    const maxYear = new Date().getFullYear() // 不能超过当前年

    const currentYear = ref(new Date().getFullYear()) // 标题年，当前选中年

    const increaseActive = computed(() => maxYear !== currentYear.value) // 可以选择下一年

    const monthList = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
    // 默认安装数据全部为零
    const initMonthData = monthList.map(item => ({
      month: item,
      description: { count: 0 }
    }))

    const fillMonth = ref(initMonthData)

    const isLoading = ref(true) // 是否显示loading

    onMounted(() => {
      init()
    })

    const init = () => {
      refresh()
    }

    const decreaseYear = async () => {
      currentYear.value--
      refresh()
    }

    const increaseYear = async () => {
      if (!increaseActive.value) return
      currentYear.value++
      refresh()
    }

    const selectMonth = n => {
      const year = currentYear.value
      const monthIndex = n + 1
      const query = { year, monthIndex }
      router.push({
        name: 'MonthInstallInfo',
        query: {
          year,
          monthIndex,
          ...route.query
        }
      })
    }
    // 请求数据
    async function refresh() {
      isLoading.value = true
      const [err, { data }] = await to(
        http_clean.post('car/statisticsInstallDeviceCountByDay1ForH5.html', { year: currentYear.value })
      )
      isLoading.value = false
      if (err) {
        return
      }
      // 组装每个月数据
      // data 中按顺序排好的每个月数据
      fillMonth.value.forEach((item, i) => {
        if (data[i] !== undefined) {
          const { count, preCount } = data[i]
          item.description = { count, preCount }
        } else {
          item.description = { count: 0 }
        }
      })
    }

    return {
      prevImg,
      nextImgDisable,
      refresh,
      isLoading,
      currentYear,
      increaseActive,
      decreaseYear,
      increaseYear,
      fillMonth,
      selectMonth
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/style/variables.scss';
.month {
  padding-bottom: 6px;
  transform: translate3d(0, 0, 0);
  .header {
    margin: 12px 10px 0;
    font-size: 16px;
    color: $blue;
    height: 36px;
    line-height: 36px;
    &__text {
      width: 140px;
      text-align: center;
    }
    &__action {
      display: flex;
      align-items: center;
    }
  }
  .content {
    margin: 16px 10px 2px;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    align-content: flex-start;
    .grid {
      width: 100px;
      height: 100px;
      background: #eff4ff;
      border-radius: 8px;
      margin: 4px 0;
      overflow: hidden;
      .date {
        width: 36px;
        font-size: 14px;
        line-height: 22px;
        background: #dce7ff;
        color: #20284a;
        border-radius: 0 0 7px 0;
        letter-spacing: -0.2px;
      }
      .count {
        margin-top: 4px;
        font-weight: 600;
        font-size: 30px;
        line-height: 42px;
        color: #1c74f2;
      }

      .pre-count {
        margin-top: 4px;
        font-size: 14px;
        line-height: 20px;
        color: #8f9399;
      }

      &.in-active {
        opacity: 0.5;
        .count {
          color: #a2a5b3;
        }
      }
    }
  }
  .mask {
    background-color: #f7f8fae3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    van-loading {
      z-index: 11;
    }
  }
}
.rotated {
  transform: rotate(0.5turn);
}
.increaseDisable {
  color: #909399;
}
</style>
