<template>
  <div class="calendar w-100">
    <div class="header flex-spb">
      <div @click="decreaseMonth" class="align-center">
        <van-image width="26" height="26" :src="prevImg" />
      </div>
      <div class="header__text">{{ currentYear + '年' + currentMonth }}</div>
      <div @click="increaseMonth" class="align-center">
        <van-image
          width="26"
          height="26"
          :src="disableNext ? nextImgDisable : prevImg"
          :class="{ rotated: !disableNext }"
        />
      </div>
    </div>
    <div class="content">
      <div class="title flex">
        <div class="title__day flex-main" v-for="item in weeks" :key="item">{{ item }}</div>
      </div>
      <div class="dates flex-spb">
        <div
          :class="['grid', { hidden: item.disabled }]"
          v-for="(item, key) in fillDay"
          :key="key"
          @click="showDetailByDay(item.day)"
        >
          <div class="grid-inner position-relative" :class="{ 'in-active': item.count === 0 }">
            <div class="day position-absolute">
              {{ item.day }}
            </div>
            <div class="count">
              {{ item.count }}
            </div>
            <div class="pre-count">
              {{ item.preCount }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mask position-fixed flex-center" v-if="isLoading">
      <van-loading type="spinner" color="#c8c9cc" vertical>加载中...</van-loading>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { http_clean } from '@/utils/request'
import prevImg from '@/assets/calendar/caldendar_prev.png'
import nextImgDisable from '@/assets/calendar/caldendar_next_disable.png'
export default {
  name: 'Calendar',
  setup() {
    const router = useRouter()
    const route = useRoute()
    const isLoading = ref(true) // 是否显示loading

    let monthIndex = 0
    //每月对应的中文
    const months = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
    // 表头
    const weeks = ref(['周一', '周二', '周三', '周四', '周五', '周六', '周日'])
    const currentYear = ref(new Date().getFullYear()) //当前选中年
    const maxYear = new Date().getFullYear() // 时间选择，不能超过当前年
    const maxMonth = new Date().getMonth() // 时间选择，不能超过当前月
    const disableNext = computed(() => currentYear.value == maxYear && months[maxMonth] == currentMonth.value) // 是否禁止选择下一个

    //每个月对应最后一天，index为Date.getMonth()获得的值,闰年通过if进行拦截,另外做处理
    const monthLastDay = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

    const currentMonth = ref(new Date().getMonth()) //当前月份
    let startDay = -1 //本月开始第一天是周几
    let thisMonthLastDay = 31 //本月最后一天是几号
    let prevLastDay = 31 //上个月最后一天是几号

    // 日期数据一直保持 42 天，前后会补空位
    // disabled 不属于当前月份的 disabled，目前隐藏，后续可能修改展示样式
    // day 本月几号，count 安装数量
    const fillDay = ref(Array(42).fill({ disabled: true, day: 1, count: 0 }))

    onMounted(() => {
      init()
    })

    const init = () => {
      const date = new Date()
      currentYear.value = date.getFullYear() //获取当前年
      const isLeap = isLeapYear(currentYear.value) //判断闰年
      monthIndex = date.getMonth() //获取当前月份的index
      currentMonth.value = months[monthIndex] //获取当前月份的中文
      startDay = new Date(currentYear.value + '/' + (monthIndex + 1) + '/' + '01').getDay() //本月开始第一天是周几
      if (isLeap && monthIndex === 1) {
        thisMonthLastDay = 29
      } else {
        thisMonthLastDay = monthLastDay[monthIndex] //获取本月最后一天
      }

      if (isLeap && monthIndex === 2) {
        // 闰年三月 上个月是闰月需要处理
        prevLastDay = 29
      } else if (monthIndex === 0) {
        // 12 月变上一年
        prevLastDay = 31
      } else {
        prevLastDay = monthLastDay[monthIndex - 1] //上个月最后一天
      }
      refresh()
    }

    const decreaseMonth = () => {
      if (monthIndex === 0) {
        monthIndex = 11
        currentYear.value = currentYear.value - 1
      } else {
        monthIndex--
      }
      const isLeap = isLeapYear(currentYear.value) //判断闰年
      currentMonth.value = months[monthIndex] //获取当前月份的中文

      startDay = new Date(currentYear.value + '/' + (monthIndex + 1) + '/' + '01').getDay() //本月开始第一天是周几
      if (isLeap && monthIndex === 1) {
        thisMonthLastDay = 29
      } else {
        thisMonthLastDay = monthLastDay[monthIndex] //获取本月最后一天
      }
      if (isLeap && monthIndex === 2) {
        prevLastDay = 29
      } else if (monthIndex === 0) {
        prevLastDay = 31
      } else {
        prevLastDay = monthLastDay[monthIndex - 1] //上个月最后一天
      }
      refresh()
    }
    const increaseMonth = () => {
      // 到最大月直接返回
      if (disableNext.value) return
      if (monthIndex === 11) {
        monthIndex = 0
        currentYear.value = currentYear.value + 1
      } else {
        monthIndex++
      }
      const isLeap = isLeapYear(currentYear.value) //判断闰年
      currentMonth.value = months[monthIndex] //获取当前月份的中文

      startDay = new Date(currentYear.value + '/' + (monthIndex + 1) + '/' + '01').getDay() //本月开始第一天是周几

      if (isLeap && monthIndex === 1) {
        thisMonthLastDay = 29
      } else {
        thisMonthLastDay = monthLastDay[monthIndex] //获取本月最后一天
      }
      if (isLeap && monthIndex === 2) {
        prevLastDay = 29
      } else if (monthIndex === 0) {
        prevLastDay = 31
      } else {
        prevLastDay = monthLastDay[monthIndex - 1] //上个月最后一天
      }
      refresh()
    }
    const showDetailByDay = day => {
      const year = currentYear.value
      const query = { day, year, monthIndex }
      router.push({
        name: 'DayInstallInfo',
        query: {
          ...route.query,
          ...query
        }
      })
    }

    function refresh() {
      const params = {
        year: currentYear.value,
        month: monthIndex + 1
      }
      formatInstallData(params)
    }

    async function formatInstallData(params) {
      isLoading.value = true
      params.month < 10 && (params.month = `0${params.month}`)
      const data = await http_clean.post('car/statisticsInstallDeviceCountByDay1ForH5.html', params)
      const formattedData = {}
      if (data && data.data) {
        data.data.forEach(el => {
          let temp = {}
          // 接口返回对应天的安装数据
          const day = Number(el.name) // 天数
          const { count, preCount } = el
          temp = { count, preCount }
          formattedData[day] = temp
        })
        const param = { startDay, thisMonthLastDay, prevLastDay, formattedData }
        fillDay.value = calcDayArr(param)
        isLoading.value = false
      }
    }
    return {
      prevImg,
      nextImgDisable,
      disableNext,
      refresh,
      weeks,
      isLoading,
      currentYear,
      currentMonth,
      fillDay,
      decreaseMonth,
      increaseMonth,
      showDetailByDay
    }
  }
}
// 获取每月第一天是周几  0-6 0：周天 1：周一 ...
// function getMonthFirstDay(date) {
//   date || (date = new Date())
//   date.setDate(1)
//   const startDay = date.getDay()
//   return startDay
// }

// 格式化当月每日安装信息
function calcDayArr(params) {
  let { startDay, thisMonthLastDay, prevLastDay, formattedData } = params
  let tempArr = [] //最后返回的月份信息数组
  // 填充上个月需要展示的时间数据
  if (startDay === 0) startDay = 7
  for (let i = 0; i < startDay - 1; i++) {
    // 计算日历第一天是几号，使用上月总天数减去是星期几
    const dayNum = prevLastDay - i
    const temp = { disabled: true, day: dayNum }
    tempArr.unshift(temp)
  }
  for (let i = 1; i <= thisMonthLastDay; i++) {
    const dayNum = i
    const temp = { disabled: false, day: dayNum }
    if (formattedData[i] !== undefined) {
      temp.count = formattedData[i].count
      temp.preCount = formattedData[i].preCount
    } else {
      temp.count = 0
      temp.preCount = 0
    }
    tempArr.push(temp)
  }
  for (let i = 1; tempArr.length < 42; i++) {
    const dayNum = i
    const temp = { disabled: true, day: dayNum }
    tempArr.push(temp)
  }
  return tempArr
}

//判断闰年
function isLeapYear(year) {
  const cond1 = year % 4 == 0 //条件1：年份必须要能被4整除
  const cond2 = year % 100 != 0 //条件2：年份不能是整百数
  const cond3 = year % 400 == 0 //条件3：年份是400的倍数
  //当条件1和条件2同时成立时，就肯定是闰年，所以条件1和条件2之间为“与”的关系。
  //如果条件1和条件2不能同时成立，但如果条件3能成立，则仍然是闰年。所以条件3与前2项为“或”的关系。
  //所以得出判断闰年的表达式：
  const cond = (cond1 && cond2) || cond3
  if (cond) {
    return true
  } else {
    return false
  }
}
</script>
<style lang="scss" scoped>
@import '@/style/variables.scss';
.calendar {
  padding-bottom: 6px;
  transform: translate3d(0, 0, 0);
  .header {
    margin: 12px 10px 0;
    font-size: 16px;
    color: $blue;
    height: 36px;
    line-height: 36px;
    &__text {
      width: 140px;
      text-align: center;
    }
  }
  .content {
    .title {
      margin-top: 26px;
      font-size: 14px;
      line-height: 20px;
      color: #a2a5b3;
      &__day {
        text-align: center;
        height: 34px;
        line-height: 34px;
        font-size: 14px;
      }
    }
    .dates {
      margin-top: 24px;
      flex-flow: row wrap;
      align-content: flex-start;
      padding: 0 -4px;
      .grid {
        flex: 0 0 14%;
        padding: 0 4px;
        box-sizing: border-box;
        text-align: center;
        margin-bottom: 17px;
        .grid-inner {
          box-sizing: border-box;
          height: 50px;
          background-image: linear-gradient(180deg, #6494ff61 0%, #eff4ff00 100%);
          border-radius: 4px;

          .day {
            width: 16px;
            height: 16px;
            font-size: 12px;
            line-height: 16px;
            background: #ffffff;
            color: #20284a;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
          }

          .count {
            padding-top: 9px; // 放置 margin 溢出
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #1c74f2;
          }
          .pre-count {
            margin-top: 3px;
            line-height: 16px;
            font-size: 11px;
            color: #8f9399;
          }

          &.in-active {
            background-image: linear-gradient(180deg, #eff4ff 0%, #eff4ff00 100%);

            .day {
              color: #6a6c75;
            }
            .count {
              color: #a2a5b3;
            }
          }
        }

        &.hidden {
          opacity: 0;
        }
      }
    }
  }
  .rotated {
    transform: rotate(0.5turn);
  }
  .hidden {
    opacity: 0;
  }
  .mask {
    background-color: #f7f8fae3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    van-loading {
      z-index: 11;
    }
  }
}
</style>
