// 公司安装信息平级转树
export function findChildCompany(arr) {
  // 公司安装信息： companyName：公司名称  parentName：父级公司名称  count：当日/当月安装量 totalInstallCount：累计安装总量
  const result = []

  const comMap = new Map()
  for (let item of arr) {
    // 单独展示的父级
    if (item.nodeType === 'singleNode') {
      const temp = {
        name: item.companyName,
        count: item.count,
        totalInstallCount: item.totalInstallCount
      }
      result.push(temp)
      continue
    }
    // map 不包含当前公司，就写入
    if (!comMap.has(item.companyName)) {
      comMap.set(item.companyName, { child: [] })
    }
    // 不能修改 child 不然会丢失子级信息
    const com = comMap.get(item.companyName)
    com.name = item.companyName
    com.parentName = item.parentName
    com.count = item.count
    com.totalInstallCount = item.totalInstallCount
    // 顶级公司写入
    if (!com.parentName) {
      result.push(com)
      continue
    }
    // 没有父级就写入
    if (!comMap.has(item.parentName)) {
      comMap.set(item.parentName, { child: [] })
    }
    const parent = comMap.get(item.parentName)

    parent.child.push(com)
  }
  // 后端返回的是排好序的，不需要前端排序
  // result.sort((a, b) => {
  //   return b.count - a.count
  // })
  return result
}

//判断闰年
export function isLeapYear(year) {
  const cond1 = year % 4 === 0 //条件1：年份必须要能被4整除
  const cond2 = year % 100 !== 0 //条件2：年份不能是整百数
  const cond3 = year % 400 === 0 //条件3：年份是400的倍数
  //普通闰年：公历年份是4的倍数，且不是100的倍数的，为闰年（如2004年、2020年等就是闰年）
  // 世纪闰年：公历年份是整百数的，必须是400的倍数才是闰年（如1900年不是闰年，2000年是闰年）。
  const cond = (cond1 && cond2) || cond3
  return cond
}
