<template>
  <NavBar>
    <template #right>
      <van-image
        :class="['block', { rotation: reqLoading }]"
        width="20"
        height="20"
        :src="refreshIcon"
        @click="refreshData"
      />
    </template>
  </NavBar>

  <TitleBar @select="selectMonth" :titleDate="titleDate" :initIndex="initMonth" />
  <div ref="container" class="month-install">
    <BetterScroll
      class="content position-relative"
      :probeType="3"
      :contentIndex="2"
      ref="scrollEl"
      enablePullDown
      :pullingDownHandler="pullDownHandler"
      @scroll="scrollHandler"
    >
      <SortBar ref="titleRef" v-model="sortType" :count="totalCount" :options="OPTIONS" />
      <div class="pulldown-wrapper position-absolute">
        <div v-show="beforePullDown">
          <span>下拉刷新</span>
        </div>
        <div v-show="!beforePullDown">
          <div v-show="isPullingDown">
            <span>加载中...</span>
          </div>
          <div v-show="!isPullingDown">
            <span>{{ isRequestErr ? '请求失败' : '刷新完成' }}</span>
          </div>
        </div>
      </div>

      <div class="bg-white">
        <div :class="['grid', 'title', { 'visi-hidden': titleHidden }]">
          <div>厂区名称</div>
          <div ref="widthEl" class="blue text-center">新增</div>
          <div class="green text-center">累计</div>
        </div>
        <Company
          class="line companies"
          titleBackground="background-image: linear-gradient(90deg, #f3f8fda8 0%, #f2f8ff 100%);"
          v-for="(item, index) in renderTree"
          :key="item.companyId"
          :rank="index"
          :name="item.name"
          :count="item.count"
          :totalInstallCount="item.totalInstallCount"
          :children="item.children"
          emphasize
        />
        <div v-show="renderTree.length" class="text-center padding-8" style="color: #dcdee0">没有更多数据了</div>
        <!-- 空提示 -->
        <van-empty v-if="!reqLoading && !renderTree.length" image="search" description="未查询到相关数据" />
      </div>

      <div class="grid title position-absolute fixed-top w-100" v-show="showTop">
        <div>厂区名称</div>
        <div class="blue text-center">新增</div>
        <div class="green text-center">累计</div>
      </div>

      <div @click="backToTop" class="affix" v-if="showTop">
        <van-icon name="arrow-up" />
      </div>
    </BetterScroll>
  </div>
</template>
<script>
import { watch, computed, shallowRef, ref, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import BetterScroll from '@/components/scroll/scroll.vue'

import refreshIcon from '@/assets/navbarIcon/refresh.png'

import http from '@/utils/request'
import TitleBar from '../components/title-bar.vue'
import SortBar from '../components/sort-bar.vue'

import Company from '../components/company.vue'

import { OPTIONS, useScroll, usePullDown, toTree } from '../common/hooks'

// 生成顶部标题区域
function useTitle() {
  const titleDate = ref([])
  const route = useRoute()
  let { year, monthIndex } = route.query

  const initMonth = Number(monthIndex)
  for (let i = 1; i <= 12; i++) {
    let month
    if (i < 10) {
      month = `0${i}`
    } else {
      month = i
    }
    let tempStr = year + '-' + month
    titleDate.value.push(tempStr)
  }

  return {
    titleDate,
    initMonth
  }
}

export default {
  name: 'MonthInstallInfo',
  components: {
    BetterScroll,
    TitleBar,
    SortBar,
    Company
  },
  setup() {
    // 统计类型
    const sortType = ref(1)
    // 类型改变自动请求参数
    watch(sortType, () => {
      refreshData()
    })

    const { beforePullDown, isPullingDown, isRequestErr, pullDownHandler } = usePullDown(refreshData)

    const route = useRoute()
    const currentYear = computed(() => route.query.year)

    const currentIndex = ref(-1)
    // 渲染的树
    const renderTree = ref([])

    // 头部统计数据
    const totalCount = computed(() => {
      if (renderTree.value.length) {
        let sum
        if (sortType.value === 1) {
          sum = renderTree.value.reduce((acc, com) => acc + com.count, 0)
        } else {
          sum = renderTree.value.reduce((acc, com) => acc + com.totalInstallCount, 0)
        }
        return sum
      }
      return 0
    })

    const reqLoading = ref(false)

    const { titleDate, initMonth } = useTitle()
    const { container, widthEl, scrollEl, titleRef, showTop, scrollHandler, backToTop } = useScroll()

    const selectMonth = month => {
      if (currentIndex.value === month) return
      currentIndex.value = month
      // scrollEl.value && scrollEl.value.scrollTo(0, 0)
      refreshData()
    }

    async function refreshData() {
      if (reqLoading.value) return
      await formatInstallData({
        year: currentYear.value,
        month: currentIndex.value < 10 ? `0${currentIndex.value}` : currentIndex.value,
        sortType: sortType.value
      })
    }

    const titleHidden = ref(false)
    // 请求数据
    async function formatInstallData(params) {
      try {
        reqLoading.value = true
        const { data } = await http.post('car/statisticsInstallDeviceCountByDay1ForH5DetailV2.html', params)
        titleHidden.value = data.length === 0
        renderTree.value = toTree(data)
        await nextTick()
        scrollEl.value.scrollTo(0, 0)
      } catch (e) {
        // 抛出错误
        throw new Error(e)
      } finally {
        reqLoading.value = false
      }
    }

    return {
      refreshIcon,
      OPTIONS,
      titleDate,
      sortType,
      initMonth,
      currentIndex,
      totalCount,
      selectMonth,
      titleHidden,
      renderTree,
      container,
      widthEl,
      scrollEl,
      titleRef,
      showTop,
      backToTop,
      scrollHandler,
      reqLoading,
      refreshData,
      beforePullDown,
      isPullingDown,
      isRequestErr,
      pullDownHandler
    }
  }
}
</script>
<style lang="scss" scoped>
.month-install {
  margin-top: 8px;
  height: 0;
  flex-grow: 1;
}
.content {
  height: 100%;
  overflow: hidden;
  font-size: 14px;
  color: #404867;
}

.title {
  padding: 11px 12px;
  align-items: center;
  font-size: 14px;
}
.grid {
  display: grid;
  grid-template-columns: 2.2fr 1fr 1fr;
}

.line {
  border-bottom: 1px solid;
  border-image: linear-gradient(0deg, #fff, #f2f8ff 40%, #00000014);
}
.line::after {
  background-color: #fff;
  box-shadow: inset 0 2px 4px #00000014;
}
.fixed-top {
  top: 0;
  background-color: #fff;
  box-sizing: border-box;
}

.affix {
  height: 32px;
  width: 32px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.padding-8 {
  padding: 8px;
}

.background-color {
  background-image: linear-gradient(90deg, #f3f8fda8 0%, #f2f8ff 100%);
}

.companies {
  background-color: #fff;
}

.pulldown-wrapper {
  text-align: center;
  line-height: 32px;
  z-index: -1;
  color: #999;
  width: 100%;
  padding: 6px;
}

.visi-hidden {
  visibility: hidden;
}
</style>
