<template>
  <div class="wrapper" style="$route.meta.isSub ? margin-bottom: 50px; : ''">
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" v-if="$route.meta.keepAlive" :key="$route.name" />
      </keep-alive>
      <component :is="Component" v-if="!$route.meta.keepAlive" />
    </router-view>
    <Docker v-if="!$route.meta.isSub" />
  </div>
</template>

<script>
import Docker from './Docker'
import SearchCar from '@/views/searchCar/SearchCar'
import { Loading } from 'vant'
export default {
  components: {
    Docker,
    SearchCar,
    [Loading.name]: Loading
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/variables.scss';
.wrapper {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  :deep > .container {
    flex: 1;
    overflow-y: scroll;
    width: 100%;
  }
}
@supports (bottom: env(safe-area-inset-bottom)) {
  .wrapper {
    height: calc(var(--vh, 1vh) * 100 - env(safe-area-inset-bottom));
  }
}
</style>
