<template>
  <div class="chart column">
    <!-- 统计切换tabs -->
    <div class="tabs align-center position-relative">
      <div @click="selectTab(1)" :class="['tab', 'short', { active: activeTab === 1 }]">月统计</div>
      <div @click="selectTab(2)" :class="['tab', 'short', { active: activeTab === 2 }]">日统计</div>
      <div @click="selectTab(3)" :class="['tab', 'long', { active: activeTab === 3 }]">总量月统计</div>
      <div @click="selectTab(4)" :class="['tab', 'long', { active: activeTab === 4 }]">总量日统计</div>
    </div>

    <div class="header flex-spb">
      <div>
        <van-image width="20" height="12" :src="chartStatusIcon" />
        <span style="margin-left: 10px">
          {{
            activeTab === 1
              ? '月安装量同比'
              : activeTab === 2
              ? '日安装量同比'
              : activeTab === 3
              ? '设备总量月同比'
              : '设备总量日同比'
          }}
        </span>
      </div>
      <div class="date" @click="pickDate">
        <span>{{ showSelected }}</span>
        <van-icon name="arrow-down" />
      </div>
    </div>

    <div class="chart-wrapper" style="position: relative">
      <div>
        <chart-view height="400px" style="margin-top: 8px" :chartOption="chartOption" ref="chartRef"></chart-view>
      </div>
    </div>
  </div>

  <!-- 选择年弹出框 -->
  <van-popup v-model:show="yearShow" position="bottom" round>
    <van-picker
      title="选择年份"
      :columns="columns"
      :default-index="defaultIndex"
      @confirm="yearConfirm"
      @cancel="yearShow = false"
    />
  </van-popup>
  <!-- 选择年弹出框end -->

  <!-- 选择年月弹出框 -->
  <van-popup v-model:show="monthShow" position="bottom" round>
    <van-datetime-picker
      v-model="pickerDate"
      type="year-month"
      title="选择年月"
      :formatter="monthFormatter"
      @confirm="monthConfirm"
      @cancel="monthShow = false"
    />
  </van-popup>
  <!-- 选择年月弹出框end -->
</template>
<script>
import { ref, onActivated } from 'vue'
import { useStore } from 'vuex'
import { Toast } from 'vant'
import moment from 'moment'

import { throttle } from '@/utils/common'

import ChartView from '@/components/echart/index.vue'

import chartStatusIcon from '@/assets/home/chartStatusIcon.png'

export default {
  components: { ChartView },
  setup() {
    const store = useStore()

    const chartRef = ref()

    /* 中间 echart 区域的状态 */
    const currentDate = new Date() // 当前时间

    const currentYear = currentDate.getFullYear() // 当前年
    const currentMonth = currentDate.getMonth() + 1 // 当前月

    const showSelected = ref(currentYear + '年') // 时间选中的展示值

    const columns = ref([]) //可选的年信息，当前年之前20年
    const defaultIndex = ref(20) //默认选中年信息中最后一条数据
    for (let i = 20; i >= 0; i--) {
      columns.value.push(`${currentYear - i}年`)
    }

    let selectedDates = {
      // 选中的年月信息，将选择后的数据保存下来，切换展示上一次的值
      installYear: currentYear + '年', // 月统计选中年份
      installTotalYear: currentYear + '年', // 总量月统计选中年份
      installMonth: currentYear + '年' + currentMonth + '月', // 日统计选中月份
      installTotalMonth: currentYear + '年' + currentMonth + '月' // 总量日统计选中月份
    }

    const chartOption = ref({})

    // echart展示栏的切换事件
    const activeTab = ref(1)
    const selectTab = throttle(type => {
      if (activeTab.value === type) return
      // 保存当前选中 tab
      activeTab.value = type
      // 获取选中下滑线滑动距离
      let data
      // 根据当前选中 tab，切换选中时间，请求对应数据
      // selectedDates 缓存了上次选择的时间
      switch (type) {
        case 1:
          showSelected.value = selectedDates.installYear
          break
        case 2:
          showSelected.value = selectedDates.installMonth
          break
        case 3:
          showSelected.value = selectedDates.installTotalYear
          break
        case 4:
          showSelected.value = selectedDates.installTotalMonth
          break
        default:
          break
      }

      const year = showSelected.value.slice(0, 4)
      if (type % 2 === 1) {
        data = { year, type }
      } else {
        const month = showSelected.value.slice(5, -1)
        data = { year, month, type }
      }
      // 绘制 echart
      getChartData(data)
    }, 100)

    const pickerDate = ref(currentDate) //年月选择器选中的数据
    const yearShow = ref(false) // 选择年份的弹框
    const monthShow = ref(false) // 选择月份的弹框
    // echart 时间选择弹框初始化
    const pickDate = () => {
      const type = activeTab.value
      // 选择年份
      if (type % 2 === 1) {
        const arr = columns.value
        const year = type === 1 ? selectedDates.installYear : selectedDates.installTotalYear
        defaultIndex.value = arr.indexOf(year)
        yearShow.value = true
        return
      }
      // 选择月份
      const dateStr = type === 2 ? selectedDates.installMonth : selectedDates.installTotalMonth
      const y = dateStr.slice(0, 4)
      const m = dateStr.slice(5, -1) - 1 // month index
      pickerDate.value = moment().year(y).month(m).toDate()
      monthShow.value = true
    }

    // 年份选择确认事件
    const yearConfirm = value => {
      yearShow.value = false
      const year = value.slice(0, 4)
      showSelected.value = value

      getChartData({ year, type: activeTab.value })
      if (activeTab.value === 1) {
        selectedDates.installYear = value
        return
      }
      selectedDates.installTotalYear = value
    }

    /**
     * @methods monthConfirm 对选中的年月信息进行格式化并重绘echart
     * @params [String] 选中日期的date字符串
     */
    const monthConfirm = value => {
      const temp = new Date(value)
      const year = temp.getFullYear()
      const month = temp.getMonth() + 1
      showSelected.value = `${year}年${month}月`
      monthShow.value = false

      getChartData({ year, month, type: activeTab.value })
      if (activeTab.value === 2) {
        selectedDates.installMonth = `${year}年${month}月`
        return
      }
      selectedDates.installTotalMonth = `${year}年${month}月`
    }

    // 年月选择格式化
    const monthFormatter = (type, val) => {
      if (type === 'year') {
        return `${val}年`
      }
      if (type === 'month') {
        return `${val}月`
      }
      return val
    }

    // 初始化
    const init = () => {
      activeTab.value = 1
      try {
        showSelected.value = currentDate.getFullYear() + '年'
        selectedDates = {
          // 选中的年月信息，将选择后的数据保存下来，只展示一个框
          installYear: currentDate.getFullYear() + '年', // 月统计选中年份
          installTotalYear: currentDate.getFullYear() + '年', // 总量月统计选中年份
          installMonth: currentDate.getFullYear() + '年' + (currentDate.getMonth() + 1) + '月', // 日统计选中月份
          installTotalMonth: currentDate.getFullYear() + '年' + (currentDate.getMonth() + 1) + '月' // 总量日统计选中月份
        }
        const year = selectedDates.installYear.slice(0, 4)

        getChartData({ year: year, type: 1 })
      } catch (error) {
        Toast('获取数据异常')
      }
    }
    init()

    function getChartData(data) {
      store.dispatch('homeChartRequest', data).then(() => {
        const chartData = store.state.homeState['chartData' + activeTab.value]
        const option = formatChartOption(chartData.data, activeTab.value)
        chartOption.value = printChart(option)
      })
    }

    onActivated(() => {
      chartRef.value.resizeChart()
    })
    return {
      init,
      chartStatusIcon,
      chartRef,
      chartOption,
      activeTab,
      selectTab,
      showSelected,
      columns,
      defaultIndex,
      pickerDate,
      pickDate,
      yearShow,
      monthShow,
      yearConfirm,
      monthConfirm,
      monthFormatter
    }
  }
}
/**
 * 整合 echart 配置项
 * @param {Object} 接口返回的安装信息
 * @param {Number} 标志位，1 代表月安装，2 代表日安装 3 代表月安装（总） 4 代表日安装（总）
 * @return {Object} 返回的echart配置项
 */
function formatChartOption(data, type) {
  let preYear = data[0].preCycleTime.split('-')[0]
  let currentYear = data[0].currCycleTime.split('-')[0]
  let chartTitle = {
    show: false,
    left: 'left',
    text: ''
  }
  let legend = {
    top: 20,
    left: 20,
    itemWidth: 10,
    itemHeight: 10,
    data: [
      { name: preYear + '年', itemStyle: { color: '#488FF5' } },
      { name: currentYear + '年', itemStyle: { color: '#F5AB48' } }
    ]
  }
  if (type === 1 || type === 3) {
    legend.data.push('月同比增长率')
    legend.selected = {
      月同比增长率: false
    }
    chartTitle.text = '月安装量同比'
    if (type === 3) {
      chartTitle.text = '设备总量月同比'
    }
  } else if (type === 2 || type === 4) {
    legend.data.push('日同比增长率')
    legend.selected = {
      日同比增长率: false
    }
    chartTitle.text = '日安装量同比'
    if (type === 4) {
      chartTitle.text = '设备总量日同比'
    }
  }
  let series1 = {
    name: preYear + '年',
    type: 'bar',
    label: {
      show: true,
      position: 'top'
    },
    data: []
  }
  let series2 = {
    name: currentYear + '年',
    type: 'bar',
    label: {
      show: true,
      position: 'top'
    },
    data: []
  }
  let series3 = {
    name: '',
    type: 'line',
    yAxisIndex: 1,
    data: []
  }
  if (type === 1) {
    series3.name = '月同比增长率'
  } else if (type === 2) {
    series3.name = '日同比增长率'
  } else if (type === 3) {
    series3.name = '月同比增长率'
  } else {
    series3.name = '日同比增长率'
  }
  data.forEach(el => {
    series1.data.push({ value: el.preTotalCount !== 'null' ? el.preTotalCount : '-', itemStyle: { color: '#488FF5' } })
    series2.data.push({
      value: el.currTotalCount !== 'null' ? el.currTotalCount : '-',
      itemStyle: { color: '#F5AB48' }
    })
    series3.data.push(el.yoyGrowthRate)
  })
  const maxSeries1 = findMax(series1.data)
  const maxSeries2 = findMax(series2.data)
  const maxNumber = maxSeries1 > maxSeries2 ? maxSeries1 : maxSeries2
  let option = {
    chartTitle,
    legend,
    series1,
    series2,
    series3
  }
  let dayCount, dataZoom
  if (type === 2 || type === 4) {
    dayCount = {
      type: 'category',
      data: [],
      axisTick: {
        alignWithLabel: true
      },
      axisPointer: {
        type: 'shadow'
      }
    }
    let date = new Date().getDate()
    let start = date - 5
    let end = date + 1
    data.forEach(el => {
      let day = el.currCycleTime.slice(8)
      dayCount.data.push(+day + '日')
    })
    dataZoom = [
      {
        // 这个dataZoom组件，默认控制x轴。
        type: 'slider', // 这个 dataZoom 组件是 slider 型 dataZoom 组件
        startValue: start,
        endValue: end,
        zoomLock: true, // 锁定缩放大小
        brushSelect: false
      },
      {
        // 这个dataZoom组件，也控制x轴。
        type: 'inside', // 这个 dataZoom 组件是 inside 型 dataZoom 组件
        startValue: start,
        endValue: end,
        zoomLock: true // 锁定缩放大小
      }
    ]
  } else {
    dayCount = {
      type: 'category',
      data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
      axisTick: {
        alignWithLabel: true
      },
      axisPointer: {
        type: 'shadow'
      }
    }
    let monthIndex = new Date().getMonth()
    let start, end
    if (monthIndex >= 3 && monthIndex <= 9) {
      start = monthIndex - 3
      end = monthIndex + 2
    } else if (monthIndex < 3) {
      start = 0
      end = 5
    } else if (monthIndex > 9) {
      start = 0
      end = 6
    }
    dataZoom = [
      {
        // 这个dataZoom组件，默认控制x轴。
        type: 'slider', // 这个 dataZoom 组件是 slider 型 dataZoom 组件
        realtime: true,
        startValue: start,
        endValue: end,
        zoomLock: true, // 锁定缩放大小
        brushSelect: false
      },
      {
        // 这个dataZoom组件，也控制x轴。
        type: 'inside', // 这个 dataZoom 组件是 inside 型 dataZoom 组件
        startValue: start,
        endValue: end,
        zoomLock: true // 锁定缩放大小
      }
    ]
  }
  option = { ...option, dayCount, dataZoom, maxNumber }
  return option
}
function printChart(setting) {
  const max = setting.maxNumber * 1.2
  let option = {
    title: setting.chartTitle,
    legend: setting.legend,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        }
      },
      formatter: function (params) {
        let relVal = params[0].name
        for (let i = 0, l = params.length; i < l; i++) {
          if (i !== 2) {
            relVal += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value + '台'
          } else {
            relVal += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + (params[i].value || '-') + '%'
          }
        }
        return relVal
      }
    },
    grid: {
      top: '85',
      left: '60',
      right: '40',
      bottom: '70'
    },
    xAxis: setting.dayCount,
    yAxis: [
      {
        name: '安装量/台',
        scale: true,
        min: 0,
        max: max.toFixed(0)
      },
      {
        name: '增长率/%',
        scale: true,
        splitLine: false
      }
    ],
    dataZoom: setting.dataZoom,
    series: [setting.series1, setting.series2, setting.series3]
  }
  return option
}

function findMax(arr) {
  let max = 0
  for (let i = 0; i < arr.length; i++) {
    const val = arr[i].value
    if (val !== '-') {
      max = max > Number(val) ? max : Number(val)
    }
  }
  return max
}
</script>
<style lang="scss" scoped>
.chart {
  overflow: hidden;
  margin-top: 4px;
  margin-top: 20px;
  .tabs {
    height: 28px;
    line-height: 28px;
    background-color: #fff;
    .tab {
      height: 28px;
      text-align: center;
      font-size: 14px;
      color: #a2a5b3;
      letter-spacing: 1.05px;
      margin-left: 12px;
      &:first-child {
        margin-left: 0;
      }
      &.short {
        width: 66px;
      }
      &.long {
        width: 96px;
      }
      &.active {
        background: #1c74f2;
        box-shadow: 0 2px 8px 0 rgba(28, 116, 242, 0.5);
        border-radius: 0 6px 6px 6px;
        color: #fff;
      }
    }
  }

  .header {
    margin-top: 24px;
    .date {
      height: 26px;
      padding: 0 6px;
      line-height: 26px;
      border: 1px solid #cdcfd6;
      border-radius: 14px;
      text-align: center;
    }
  }

  .chart-wrapper {
    margin-top: 12px;
    background-color: #fff;
    border: 1px solid #ededed;
    border-radius: 12px;
  }
}
</style>
