<template>
  <div ref="rootRef">
    <slot></slot>
  </div>
</template>

<script>
import { ref } from 'vue'
import useScroll from './useScroll'
export default {
  name: 'scroll',
  props: {
    click: {
      type: Boolean,
      default: true
    },
    // 滚动元素的 index
    contentIndex: {
      type: Number,
      default: 0
    },
    // 1. probeType 为 0，在任何时候都不派发 scroll 事件，
    // 2. probeType 为 1，仅仅当手指按在滚动区域上，每隔 momentumLimitTime 毫秒派发一次 scroll 事件，
    // 3. probeType 为 2，仅仅当手指按在滚动区域上，一直派发 scroll 事件，
    // 4. probeType 为 3，任何时候都派发 scroll 事件，包括调用 scrollTo 或者触发 momentum 滚动动画
    probeType: {
      type: Number,
      default: 0
    },
    // 是否启用下拉刷新
    enablePullDown: {
      type: Boolean,
      default: false
    },
    pullingDownHandler: {
      type: Function,
      default: () => true
    }
  },
  emits: ['scroll'],
  setup(props, { emit }) {
    const rootRef = ref(null)
    const scrollTo = (x, y, time = 300) => {
      scroll.value.scrollTo(x, y, time)
    }

    const events = {
      pullingDown: props.pullingDownHandler
    }

    const config = {
      click: props.click,
      probeType: props.probeType,
      enablePullDown: props.enablePullDown
    }

    const scroll = useScroll(rootRef, { config, events, contentIndex: props.contentIndex }, emit)

    return {
      rootRef,
      scroll,
      scrollTo
    }
  }
}
</script>
