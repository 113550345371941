<template>
  <NavBar>
    <template #right>
      <van-image class="topIcon" width="20" height="20" :src="refreshIcon" @click="resetData" />
    </template>
  </NavBar>
  <BetterScroll class="wrapper" :probeType="3" ref="scrollEl" @scroll="scrollHandler">
    <div>
      <div class="chart column">
        <div class="header flex-spb">
          <div>
            <van-image width="20" height="12" :src="chartStatusIcon" />
            <span style="margin-left: 10px">续费数统计</span>
          </div>
          <div class="date" @click.stop="pickDate">
            <span>{{ showSelected }}</span>
            <van-icon name="arrow-down" />
          </div>
        </div>

        <div class="chart-wrapper" style="position: relative">
          <div>
            <chart-view height="400px" style="margin-top: 8px" :chartOption="chartOption"></chart-view>
          </div>
        </div>
      </div>
      <div class="month">
        <MonthCount ref="countRef" />
      </div>
    </div>
    <!-- 选择年弹出框 -->
    <!-- 使用 teleport body 会闪 -->
    <van-popup v-model:show="datePickerShow" position="bottom" round>
      <van-picker
        title="选择年份"
        :columns="columns"
        :default-index="defaultIndex"
        @confirm="onConfirm"
        @cancel="datePickerShow = false"
      />
    </van-popup>

    <div @click="backToTop" class="affix" v-if="showTop">
      <van-icon name="arrow-up" />
    </div>
  </BetterScroll>
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import BetterScroll from '@/components/scroll/scroll.vue'
import refreshIcon from '@/assets/navbarIcon/refresh.png'
import MonthCount from './components/MonthCount.vue'

import chartStatusIcon from '@/assets/home/chartStatusIcon.png'
import ChartView from '@/components/echart/index.vue'
import http from '@/utils/request'

export default {
  name: 'Renew',
  components: { BetterScroll, ChartView, MonthCount },

  setup() {
    const countRef = ref()
    const scrollEl = ref()
    // 刷新操做
    const resetData = () => {
      scrollEl.value.scrollTo(0, 0)
      // chartRef.value?.init()
      countRef.value?.init()
      initChart()
    }

    // 默认当前年
    const selectYear = ref(new Date().getFullYear())

    onMounted(() => {
      if (!selectYear.value) selectYear.value = new Date().getFullYear()
      drawChart(selectYear.value)
    })
    // 初始化年份选项
    const columns = ref([])
    columns.value = initColumns()

    // 选择年弹出
    const datePickerShow = ref(false)

    const defaultIndex = computed(() => selectYear.value - 2015)
    // 显示时间选择 picker
    const pickDate = () => {
      datePickerShow.value = true
    }
    // 选择年
    const onConfirm = data => {
      drawChart(data.value)
      datePickerShow.value = false
    }
    // e-chart legend 配置
    const chartLegend = computed(() => {
      const cur = selectYear.value
      const preYear = cur - 1
      const temp = {
        top: 20,
        left: 20,
        itemWidth: 10,
        itemHeight: 10,
        selected: { 续费率: false },
        data: [
          { name: preYear + '年', itemStyle: { color: '#488FF5' } },
          { name: cur + '年', itemStyle: { color: '#F5AB48' } }
          // '续费率'
        ]
      }

      return temp
    })

    // 续费数数据
    const preData = ref([])
    // 到期数据
    const currentData = ref([])
    // 续费率
    // const renewRate = ref([])
    const monthIndex = new Date().getMonth()
    let start, end
    if (monthIndex >= 3 && monthIndex <= 9) {
      start = monthIndex - 3
      end = monthIndex + 2
    } else if (monthIndex < 3) {
      start = 0
      end = 5
    } else if (monthIndex > 9) {
      start = 0
      end = 6
    }
    // 使用 computed 动态计算 e-chart 选项
    const chartOption = computed(() => ({
      legend: chartLegend.value,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        }
      },
      grid: {
        top: '85',
        left: '60',
        right: '40',
        bottom: '70'
      },
      xAxis: {
        type: 'category',
        data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
        axisTick: {
          alignWithLabel: true
        },
        axisPointer: {
          type: 'shadow'
        }
      },
      yAxis: [
        {
          name: '续费数/台',
          scale: true,
          min: 0,
          max: null
        }
        // {
        //   name: '续费率/%',
        //   scale: true,
        //   splitLine: false
        // }
      ],
      dataZoom: [
        {
          // 这个dataZoom组件，默认控制x轴。
          type: 'slider', // 这个 dataZoom 组件是 slider 型 dataZoom 组件
          realtime: true,
          startValue: start,
          endValue: end,
          zoomLock: true, // 锁定缩放大小
          brushSelect: false
        },
        {
          // 这个dataZoom组件，也控制x轴。
          type: 'inside', // 这个 dataZoom 组件是 inside 型 dataZoom 组件
          startValue: start,
          endValue: end,
          zoomLock: true // 锁定缩放大小
        }
      ],
      series: [
        {
          name: selectYear.value - 1 + '年',
          type: 'bar',
          label: {
            show: true,
            position: 'top'
          },
          data: preData.value
        },
        {
          name: selectYear.value + '年',
          type: 'bar',
          label: {
            show: true,
            position: 'top'
          },
          data: currentData.value
        }
        // {
        //   name: '续费率',
        //   type: 'line',
        //   yAxisIndex: 1,
        //   data: renewRate.value
        // }
      ]
    }))

    async function drawChart(year) {
      const { data } = await http.get('renewal/v2/renewalNumStatistics.html', { params: { year } })
      const preTemp = []
      const currentTemp = []
      // const rateTemp = []
      data.forEach(item => {
        preTemp.push({ value: item.preMonthRenewalNum, itemStyle: { color: '#488FF5' } })
        currentTemp.push({ value: item.monthRenewalNum, itemStyle: { color: '#F5AB48' } })
        // rateTemp.push(item.renewalRate * 100)
      })
      preData.value = preTemp
      currentData.value = currentTemp
      // renewRate.value = rateTemp
      // 请求成功才会修改选中年份
      selectYear.value = year
    }

    // 初始化年份选择数据
    function initColumns() {
      // 获取当前年
      const currentYear = new Date().getFullYear()
      const data = []
      // 从 2015 年到当前年
      for (let i = 2015; i <= currentYear; i++) {
        data.push({ text: `${i} 年`, value: i })
      }
      return data
    }

    function initChart() {
      drawChart(new Date().getFullYear())
    }

    const showTop = ref(false)

    const scrollHandler = position => {
      const distance = -position.y
      // 显示返回顶部操作
      if (distance >= 200) {
        showTop.value = true
      } else {
        showTop.value = false
      }
    }
    const backToTop = () => {
      scrollEl.value.scrollTo(0, 0)
    }

    return {
      countRef,
      refreshIcon,
      scrollEl,
      resetData,
      chartStatusIcon,
      selectYear,
      showSelected: computed(() => selectYear.value + '年'),
      datePickerShow,
      defaultIndex,
      columns,
      pickDate,
      onConfirm,
      chartOption,
      showTop,
      scrollHandler,
      backToTop
    }
  }
}
</script>

<style lang="scss" scoped>
.topIcon {
  display: block;
}
.wrapper {
  height: 0;
  flex-grow: 1;
  overflow: hidden;
}

.chart {
  overflow: hidden;
  padding: 0 10px;
  > .header {
    margin-top: 24px;
    > .date {
      height: 26px;
      padding: 0 6px;
      line-height: 26px;
      border: 1px solid #cdcfd6;
      border-radius: 14px;
      text-align: center;
    }
  }

  .chart-wrapper {
    margin-top: 12px;
    background-color: #fff;
    border: 1px solid #ededed;
    border-radius: 12px;
  }
}

.month {
  margin-top: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.affix {
  height: 32px;
  width: 32px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
</style>
