<template>
  <div>
    <CompanyRow
      :name="name"
      :install="install"
      :inventory="inventory"
      :share-company="shareCompany"
      :rest="rest"
      :has-expend="hasExpend"
      :is-expend="isExpend"
      @expend="expendHandler"
    />
    <Transition :css="false" @before-enter="onBeforeEnter" @enter="onEnter" @leave="onLeave">
      <div v-if="hasExpend && isExpend" class="children">
        <company-list v-for="(com, index) in children" v-bind="com" :key="index" />
      </div>
    </Transition>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { gsap } from 'gsap'
import CompanyRow from './company-row.vue'
export default {
  name: 'CompanyList',
  components: { CompanyRow },
  props: {
    name: {
      type: String,
      required: true
    },
    install: {
      type: Number,
      required: true
    },
    inventory: {
      type: Number,
      required: true
    },
    rest: {
      type: String
    },
    children: {
      type: Array
    },
    shareCompany: {
      type: Array
    }
  },
  setup(props, { emit }) {
    const hasExpend = computed(() => !!(props.children && props.children.length))

    const isExpend = ref(false)

    const expendHandler = () => {
      if (!hasExpend.value) return
      isExpend.value = !isExpend.value
    }

    function onBeforeEnter(el) {
      el.style.height = 0
    }
    // 用这个来开始进入动画
    function onEnter(el, done) {
      gsap.to(el, {
        duration: 0.3,
        height: '100%',
        ease: 'none',
        onComplete: done
      })
    }

    function onLeave(el, done) {
      gsap.to(el, {
        duration: 0.3,
        height: '0%',
        ease: 'none',
        onComplete: done
      })
    }

    return { hasExpend, isExpend, expendHandler, onBeforeEnter, onEnter, onLeave }
  }
}
</script>
<style lang="scss" scoped>
.children {
  padding-left: 10px;
}
</style>
