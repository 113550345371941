<template>
  <div class="sort flex-spb">
    <Dropdown class="dropdown" v-model="type" @change="changeHandler">
      <DropdownItem v-for="item in options" :key="item.value" v-bind="item" />
    </Dropdown>
    <div class="flex">
      <slot>
        <van-image class="chart-img" :src="chartPic" />
        <div class="count">{{ count }}&nbsp;台</div>
      </slot>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import chartPic from '@/assets/install/increase-chart.png'
import Dropdown from './dropdown.vue'
import DropdownItem from './dropdown-item.vue'
export default {
  components: {
    Dropdown,
    DropdownItem
  },
  props: {
    modelValue: {
      type: Number,
      require: true
    },
    count: {
      type: Number
    },
    options: {
      require: true
    }
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const type = computed({
      get() {
        return props.modelValue
      },
      set(val) {
        emit('update:modelValue', val)
      }
    })

    const changeHandler = val => {
      emit('change', val)
    }
    return { chartPic, type, changeHandler }
  }
}
</script>
<style lang="scss" scoped>
.sort {
  background-color: #f8f8f8;
  height: 40px;
  padding: 6px 12px;
  position: relative;
  .dropdown {
    height: 100%;
    line-height: 28px;
  }
  .chart-img {
    height: 20px;
    width: 20px;
  }

  .count {
    font-size: 20px;
    margin-left: 4px;
  }
}
</style>
