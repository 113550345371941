<template>
  <div class="dropdown" @click="expendHandler" ref="dropdown">
    <div class="result align-center">
      <div class="text">{{ showText }}</div>
      <van-image :class="['arrow', { turn: isExpend }]" :src="Arrow" />
    </div>

    <!-- 弹出层 -->
    <Teleport to="body">
      <Transition>
        <div class="popper" ref="popper" v-show="isExpend">
          <van-overlay
            :show="isExpend"
            @click.stop="isExpend = false"
            lock-scroll
            :custom-style="{ zIndex: 0, top: 'unset' }"
          />
          <div :class="['menu', { 'menu-show': isExpend }]">
            <slot />
          </div>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<script>
import { watch, ref, provide } from 'vue'
import Arrow from '@/assets/install/dropdown-icon.png'

export default {
  props: {
    modelValue: {
      required: true
    }
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const showText = ref('')

    const children = ref([])

    const isExpend = ref(false)

    const expendHandler = () => {
      isExpend.value = !isExpend.value
    }

    // 提供更新选中方法
    const updateValue = (value, text) => {
      showText.value = text

      isExpend.value = false

      if (value !== props.modelValue) {
        emit('update:modelValue', value)
        emit('change', value)
      }
    }

    // 父级元素
    const dropdown = ref()

    // 弹出功能
    const popper = ref()

    watch(
      () => dropdown.value,
      el => {
        // 父级渲染后计算 popper 位置
        if (el) {
          const { bottom } = el.getBoundingClientRect()
          popper.value.style.top = bottom + 'px'
        }
      }
    )

    watch(
      () => props.modelValue,
      val => {
        if (children.value.length) {
          const child = children.value.find(item => item.value === val)
          child.clickHandler()
        }
      }
    )

    const instance = {
      props, // 父组件的 props
      updateValue, // 更新选中方法
      children // dropdown-item 的实例集合
    }

    provide('dropdown-parent', instance)

    return {
      dropdown,
      Arrow,
      showText,
      isExpend,
      expendHandler,
      popper
    }
  }
}
</script>
<style lang="scss">
.dropdown {
  position: relative;
  .result {
    .text {
      font-size: 14px;
      color: #20284a;
      letter-spacing: -0.39px;
    }
    .arrow {
      margin-left: 4px;
      height: 16px;
      width: 16px;
      transform: translateY(-4px);
      transition: all 0.25s ease-out;
      &.turn {
        transform: rotate(0.5turn);
        transition: all 0.25s ease-in;
      }
    }
  }
}
</style>

<style lang="scss">
.popper {
  position: absolute;
  width: 100%;
  bottom: 0px;
  overflow: hidden;
  .menu {
    position: absolute;
    background-color: #fff;
    width: 100%;
    box-sizing: border-box;
    padding: 6px 0;
    opacity: 0;
    transform: translateY(-100%);
    transition: all 0.25s ease-out;
    &.menu-show {
      transform: translateY(0);
      opacity: 1;
      transition: all 0.25s ease-in;
    }
  }
}
</style>
