<template>
  <NavBar :is-return="false">
    <template #right>
      <van-image
        :class="['block', { rotation: isRefresh }]"
        width="20"
        height="20"
        :src="refreshIcon"
        @click="resetData"
      />
    </template>
  </NavBar>
  <div class="home column container" ref="containerRef">
    <div class="home__container">
      <!-- 车辆统计展示区域 -->
      <InstallTop ref="topRef" />

      <TabChart v-if="hasInstallPer" ref="chartRef" />

      <div class="summary" v-if="hasInstallPer">
        <div class="summary__switch">
          <div
            :class="['summary__switch__tab', { 'is-active': activeCalendar === 'day' }]"
            @click="summaryTabClick('day')"
          >
            按天汇总
          </div>
          <div
            @click="summaryTabClick('month')"
            :class="['summary__switch__tab', { 'is-active': activeCalendar === 'month' }]"
          >
            按月汇总
          </div>
        </div>
        <Calendar v-show="activeCalendar === 'day'" ref="daySumRef"></Calendar>
        <MonthSummary v-show="activeCalendar === 'month'" ref="monthSumRef"></MonthSummary>
      </div>

      <div class="inventory flex-spb" v-if="hasInventoryPer">
        <div class="count">
          <div class="number">{{ inventoryNumber }}</div>
          <div class="hint">库存总数</div>
        </div>
        <div class="action">
          <div class="btn" @click="checkInventory">厂区库存</div>
        </div>
      </div>

      <div v-if="hasRenewPer" class="renew flex-center" @click="viewRenew">
        <div class="content">
          <van-image class="renewPic" :src="renewPic" />
          <span class="title">续费统计</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onActivated, onDeactivated } from 'vue'
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import { useStore } from 'vuex'

import { http_clean } from '@/utils/request'
import { throttle } from '@/utils/common'

import refreshIcon from '@/assets/navbarIcon/refresh.png'
import Calendar from './components/Calendar.vue'
import bgGreen from '@/assets/home/home_bg_green.png'
import bgGrey from '@/assets/home/home_bg_grey.png'
import bgBlue from '@/assets/home/home_bg_blue.png'

import renewPic from '@/assets/home/renew.png'
import InstallTop from './components/InstallTop.vue'
import TabChart from './components/TabChart.vue'
import MonthSummary from './components/MonthSummary.vue'
import { INSTALL_SOURCE_KEY, INVENTORY_SOURCE_KEY, RENEW_SOURCE_KEY } from '@/const'
import eventBus, { APP_ACTIVE } from '@/utils/eventBus'
import jsBridge from '@/utils/jsBridge'
import { NATIVE_TYPE } from '@/utils/types'

// 厂区库存功能
function useInventory() {
  const inventoryNumber = ref(0)
  const router = useRouter()
  initInventory()
  const checkInventory = () => {
    router.push({ name: 'Inventory' })
  }
  async function initInventory() {
    const { data } = await http_clean.get('company/totalInventoryForH5.html')
    inventoryNumber.value = data
  }
  return {
    initInventory,
    inventoryNumber,
    checkInventory
  }
}
export default {
  name: 'Home',
  components: {
    Calendar,
    MonthSummary,
    InstallTop,
    TabChart
  },
  setup() {
    const store = useStore()
    const menus = computed(() => store.state.permission.menus) // 获取用户权限
    const hasInstallPer = computed(() => menus.value.find(el => el.sourceKey === INSTALL_SOURCE_KEY)) // 是否有安装统计查看权限
    store.dispatch('commonData/companyTreeRequest') // 获取公司树数据

    const containerRef = ref()
    const topRef = ref()
    const chartRef = ref()
    const daySumRef = ref()
    const monthSumRef = ref()

    let isDeactivated = false
    let preFreshDate = new Date()

    onActivated(() => {
      preFreshDate = new Date()
      isDeactivated = false
      // 每次进入需要刷新数据
      refresh()
      // 滚动到上次位置
      containerRef.value.scrollTop = store.state.homeScroll
    })

    onBeforeRouteLeave(() => {
      // 切换路由前更新滚动位置
      store.commit('updateHomeScroll', containerRef.value.scrollTop)
    })

    onDeactivated(() => {
      isDeactivated = true
    })

    // app 激活事件
    eventBus.on(APP_ACTIVE, info => {
      if (!isDeactivated) {
        // console.log('home 接受到激活----》', info)
        const nowDate = new Date()
        if (nowDate.getTime() - preFreshDate.getTime() > 30 * 1000) {
          preFreshDate = nowDate
          refresh()
        }
      }
    })

    // 安装汇总的选中状态
    const activeCalendar = ref('day')
    // 切换汇总类型
    const summaryTabClick = throttle(type => {
      if (activeCalendar.value !== type) activeCalendar.value = type
    }, 100)

    // 刷新安装统计日历数据
    const refreshCalendarData = () => {
      daySumRef.value.refresh()
      monthSumRef.value.refresh()
    }

    const { initInventory, inventoryNumber, checkInventory } = useInventory() // 厂区库存功能

    // 刷新效果没有使用 router.go(0) 会闪白
    // 手动刷新
    const resetData = () => {
      refresh()
      // 刷新权限
      store.dispatch('permission/getUserPermission')
    }

    const hasInventoryPer = computed(() => menus.value.find(el => el.sourceKey === INVENTORY_SOURCE_KEY))

    const isRefresh = ref(false)
    // 页面数据刷新
    const refresh = () => {
      if (isRefresh.value) return
      isRefresh.value = true
      jsBridge.jsCallNative(NATIVE_TYPE.INSTALL_COUNT, true) // 通知原生更新小组件
      topRef.value.init() // 在线、离线、总计
      // 是否有安装统计查看权限
      if (hasInstallPer.value) {
        // 刷新汇总数据 和 echart
        chartRef.value?.init()
        refreshCalendarData()
      }
      // 刷新库存数据
      if (hasInventoryPer.value) initInventory()
      setTimeout(() => {
        isRefresh.value = false
      }, 500)
    }

    const router = useRouter()
    const viewRenew = () => {
      router.push({
        name: 'Renew'
      })
    }

    return {
      refreshIcon,
      topRef,
      chartRef,
      containerRef,
      daySumRef,
      monthSumRef,
      resetData,
      activeCalendar,
      summaryTabClick,
      bgGreen,
      bgGrey,
      bgBlue,
      inventoryNumber,
      checkInventory,
      renewPic,
      viewRenew,
      hasInstallPer,
      hasInventoryPer,
      hasRenewPer: computed(() => menus.value.find(el => el.sourceKey === RENEW_SOURCE_KEY)),
      isRefresh
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/variables.scss';
@import '@/style/mixins.scss';

.home {
  align-items: center;
  font-size: 14px;

  > .header {
    font-size: 16px;
    color: $blue;
    height: 36px;
    line-height: 36px;
  }
  &__container {
    width: calc(100% - 20px);
    padding-top: 10px;
    padding-bottom: 40px;
  }

  .summary {
    margin-top: 12px;
    background-color: #fff;
    overflow: hidden;
    box-shadow: 0 2px 12px 0 rgba(32, 40, 74, 0.06);
    border-radius: 12px;
    &__switch {
      border-radius: 12px 12px 0 0;
      box-sizing: border-box;
      opacity: 0.8;
      background: #ebf0f9;
      &__tab {
        padding: 0 20px;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        box-sizing: border-box;
        color: #777c90;
      }
      &__tab.is-active {
        font-size: 16px;
        font-weight: 500;
        position: relative;
        color: $font-color;
      }
      &__tab.is-active::after {
        content: '';
        height: 4px;
        background-color: #1c74f2;
        width: 26px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%) skew(-30deg, 0deg);
        border-radius: 2px;
      }
    }
  }
}
.inventory {
  margin-top: 15px;
  border-radius: 8px;
  background-color: #ebf0f9;
  padding: 11.5px 12px 12px 15px;
  .number {
    color: #20284a;
    font-size: 26px;
    letter-spacing: 2.03px;
  }
  .hint {
    color: #888b98;
    font-size: 12px;
    letter-spacing: 0.94px;
  }
  .btn {
    width: 88px;
    height: 32px;
    background-color: #1c74f21a;
    border: 1px solid #1c74f2;
    border-radius: 18px;
    color: #1c74f2;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.renew {
  width: 134px;
  height: 42px;
  background-color: #1c74f21a;
  border: 1px solid #1c74f2;
  border-radius: 22px;
  color: #1c74f2;
  font-size: 14px;
  margin-top: 16px;
  .content {
    display: flex;
    align-items: center;
  }
  .renewPic {
    width: 22px;
    margin-right: 6px;
  }
}
</style>
