import { watch, shallowRef, ref } from 'vue'

export const OPTIONS = [
  { text: '新增数量', value: 1 },
  { text: '累计数量', value: 2 }
]

// 滚动区域
export function useScroll() {
  // 页面内容区
  const container = ref()
  // 绑定头部 title 标题元素，用来计算宽度
  const widthEl = shallowRef(null)
  // 滚动元素
  const scrollEl = shallowRef(null)
  // 计算新增，累计对应的宽度
  watch(widthEl, el => {
    const width = el.clientWidth
    if (container.value) {
      container.value.style.setProperty('--common-width', width + 'px')
    }
  })

  // 顶部组件 ref，这里使用的是 SortBar
  const titleRef = shallowRef()
  // 保存顶部距离，超出后需要隐藏
  const showTop = ref(false)

  let hiddenDiff = 0
  watch(titleRef, val => {
    const el = val.$el
    if (el) {
      hiddenDiff = el.offsetHeight
    }
  })

  const scrollHandler = position => {
    // const element = event.target
    const distance = -position.y
    // 滚动切换布局，自动隐藏顶部
    if (distance >= hiddenDiff) {
      showTop.value = true
    } else {
      showTop.value = false
    }
  }
  // 返回顶部
  const backToTop = () => {
    scrollEl.value.scrollTo(0, 0)
  }

  return {
    container,
    widthEl,
    scrollEl,
    titleRef,
    showTop,
    scrollHandler,
    backToTop
  }
}

/**
 * 下拉刷新功能
 * @param { Promise } refresh 请求方法，async 方法或者返回一个 Promise
 * @returns beforePullDown 下来刷新未开始状态;
 *          isPullingDown 下拉请求中状态;
 *          isRequestErr 请求失败状态;
 *          pullDownHandler 下拉刷新状态;
 */
export function usePullDown(refresh) {
  const beforePullDown = ref(true) //下拉刷新未开始
  const isPullingDown = ref(false) // 下拉请求中
  const isRequestErr = ref(false) // 请求失败

  const pullDownHandler = async () => {
    beforePullDown.value = false
    isPullingDown.value = true
    try {
      await refresh()
    } catch {
      isRequestErr.value = true
    } finally {
      isPullingDown.value = false
      setTimeout(() => {
        beforePullDown.value = true
        isRequestErr.value = false
      }, 500)
    }
  }

  return {
    beforePullDown,
    isPullingDown,
    isRequestErr,
    pullDownHandler
  }
}

// 转换树形
export function toTree(arr) {
  const tree = []
  // 公司 map
  const comMap = new Map()

  for (let com of arr) {
    // 如果父级公司自身有装车，nodeType 是 singleNode
    if (com.nodeType === 'singleNode') {
      // 父级公司自身安装信息也要在子级展示
      const temp = {
        name: com.companyName,
        companyId: com.companyId,
        count: com.count,
        totalInstallCount: com.totalInstallCount
      }
      // 判读有无父级，没有就生成
      if (!comMap.has(com.parentId)) {
        comMap.set(com.parentId, { children: [] })
      }
      // 将当前信息压入，当前公司的信息和父级是重复的
      // 公司信息不写入 map
      const parent = comMap.get(com.parentId)
      parent.children.push(temp)
      continue
    }
    // 可能先遍历到子级时新建父级公司信息
    // 进行判断，写入公司信息
    if (!comMap.has(com.companyId)) {
      comMap.set(com.companyId, { ...com, children: [] })
    }

    const current = comMap.get(com.companyId)
    current.name = com.companyName
    current.parentId = com.parentId
    current.count = com.count
    current.totalInstallCount = com.totalInstallCount
    // 顶级公司写入
    if (!current.parentId) {
      tree.push(current)
      continue
    }
    // 没有父级新建
    if (!comMap.has(com.parentId)) {
      comMap.set(com.parentId, { name: com.parentName, companyId: com.parentId, children: [] })
    }
    const parent = comMap.get(com.parentId)

    parent.children.push(current)
  }
  return tree
}
