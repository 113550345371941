<template>
  <div>
    <div :class="['company', 'flex', className]" v-bind="$attrs">
      <div class="text align-center">
        <slot name="prefix" />
        <div class="reference" :class="{ emphasize: emphasize }">
          {{ name }}
        </div>

        <van-icon v-if="hasExpend" name="arrow" :class="['arrow', { expend: isExpend }]" />
      </div>
      <div class="blue common-width flex-center">{{ count }}</div>
      <div class="green common-width flex-center">
        {{ totalInstallCount }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyRow',
  props: {
    className: {
      type: String
    },
    // 是否加粗名称
    emphasize: {
      type: Boolean,
      default: false
    },
    imgUrl: { type: String },
    name: {
      type: String
    },
    // 新增安装
    count: {
      type: Number,
      required: true
    },
    // 总安装量
    totalInstallCount: {
      type: Number,
      required: true
    },
    // 是否需要展开
    hasExpend: {
      type: Boolean,
      default: false
    },
    // 展开效果通过 props 控制
    isExpend: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/style/mixins.scss';
.company {
  font-size: 14px;
  line-height: 20px;
  padding: 16px 0;

  .text {
    flex-grow: 1;
    display: inline-flex;
    align-items: center;
    .arrow {
      transition: transform 0.25s ease-out;
      &.expend {
        transform: rotate(90deg);
        transition: transform 0.25s ease-in;
      }
    }
  }
}
.emphasize {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: #20284a;
}
.reference {
  width: 0;
  flex-grow: 1;
  @include ellipsis;
}
.common-width {
  width: var(--common-width);
  flex-shrink: 0;
}
</style>
