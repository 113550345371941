<template>
  <div class="row">
    <div :class="['company', 'w-100', 'flex', className]" v-bind="$attrs" @click="expendHandler">
      <div class="text">
        <slot name="name" :data="$props">
          <div class="align-center flex-expend">
            <div class="flex-expend root-name">{{ name }}</div>
          </div>
          <van-icon v-if="hasExpend" name="arrow" :class="['arrow', { expend: isExpend }]" />
          <div v-else class="w-14"></div>
        </slot>
      </div>
      <div class="blue common-width flex-center">{{ install }}</div>
      <div class="green common-width flex-center">
        <van-popover
          v-if="shareCompany && shareCompany.length && inventory"
          v-model:show="showPopover"
          overlay
          :overlay-style="{ backgroundColor: '#00000066' }"
          placement="left"
        >
          <div class="tips">
            <div class="tip-title">共享库存厂区：</div>
            <div class="tip-company" v-for="com in shareCompany" :key="com">{{ com }}</div>
          </div>
          <template #reference>
            <!-- 手动控制弹出展示，拦截冒泡 -->
            <div @click.stop="handleShareClick" class="position-relative">
              {{ inventory }}
              <van-image
                :src="shareImg"
                v-if="shareCompany && shareCompany.length"
                class="position-absolute share-icon"
              />
            </div>
          </template>
        </van-popover>
        <span v-else>{{ inventory }}</span>
      </div>
      <div class="common-width flex-center">
        {{ rest }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { Popover } from 'vant'

import shareImg from '@/assets/install/share.png'
export default {
  name: 'CompanyRow',
  components: {
    [Popover.name]: Popover
  },
  props: {
    className: {
      type: String
    },
    name: {
      type: String
    },
    install: {
      type: Number,
      required: true
    },
    inventory: {
      type: Number,
      required: true
    },
    rest: {
      type: String
    },
    // 是否需要展开
    hasExpend: {
      type: Boolean,
      default: false
    },
    // 展开效果通过 props 控制
    isExpend: {
      type: Boolean,
      default: false
    },
    shareCompany: {
      type: Array
    }
  },
  emits: ['expend'],
  setup(props, { emit }) {
    const showPopover = ref(false)
    const handleShareClick = () => {
      setTimeout(() => {
        // 显示弹出层太快，导致弹出层触发事件
        // 展开立马关闭，延迟触发
        showPopover.value = !showPopover.value
      }, 200)
    }
    const expendHandler = e => {
      if (props.hasExpend) emit('expend')
    }

    return {
      shareImg,
      showPopover,
      handleShareClick,
      expendHandler
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/style/mixins.scss';
.company {
  font-size: 14px;
  line-height: 20px;
  padding: 16px 0;

  .text {
    flex-grow: 1;
    display: inline-flex;
    align-items: center;
    .arrow {
      transition: transform 0.25s ease-out;
      &.expend {
        transform: rotate(90deg);
        transition: transform 0.25s ease-in;
      }
    }
  }

  .root-name {
    @include ellipsis;
  }
}
.share-icon {
  width: 14px;
  height: 14px;
  top: 2px;
  right: -16px;
}

.common-width {
  width: var(--common-width);
  flex-shrink: 0;
}

.tips {
  max-width: 188px;
  max-height: 206px;
  overflow-y: scroll;
  font-size: 12px;
  line-height: 1.2;
  padding: 12px 10px;
  border-radius: 4px;
  .tip-title {
    font-size: 14px;
    margin-bottom: 4px;
  }
  .tip-company {
    margin: 2px 4px;
  }
}
.w-14 {
  width: 14px;
}
</style>
