import BScroll from '@better-scroll/core'
import ObserveDom from '@better-scroll/observe-dom'
import PullDown from '@better-scroll/pull-down'
import { onActivated, onDeactivated, onMounted, onUnmounted, ref } from 'vue'

// 当数据变化的时候，DOM发生变化，自动触发scroll 的 refresh 方法
BScroll.use(ObserveDom)

// 下拉刷新
// BScroll.use(PullDown)

// 配置顶部下拉的距离来决定刷新时机
const THRESHOLD = 40
// 回弹悬停的距离。BetterScroll 在派发 pullingDown 钩子之后，会立马执行回弹悬停动画
const STOP = 30

// 下拉的配置项
const RefreshConfig = {
  threshold: THRESHOLD,
  stop: STOP
}
// 回弹事件
const TIME_BOUNCE = 800

const COMMON_CONFIG = {
  eventPassthrough: 'horizontal',
  observeDOM: true,
  bounce: false // 默认不回弹，开启回弹会将配置覆盖掉
}

const PULLING_CONFIG = {
  bounce: {
    // 下拉刷新必须开启顶部 bounce
    top: true,
    bottom: false,
    left: false,
    right: false
  },
  bounceTime: TIME_BOUNCE,
  pullDownRefresh: RefreshConfig
}

const extend = Object.assign

// 处理 config，将传入config
// 格式化为 better-scroll 可以使用的形式
function normalizeConfig(config, contentIndex) {
  const mergeConfig = extend(
    {},
    COMMON_CONFIG,
    {
      specifiedIndexAsContent: contentIndex
    },
    config
  )

  if (mergeConfig.enablePullDown) {
    // 开启下拉刷新
    BScroll.use(PullDown)
    // 启用下拉回弹，不启用无法下拉
    extend(mergeConfig, PULLING_CONFIG)
  }
  // enablePullDown 判断是否开启下拉刷新
  // better-scroll 中没有，需要删除
  delete mergeConfig.enablePullDown
  return mergeConfig
}
// 绑定事件
function bindEvents(config, events, el) {
  if (!events || !el) return
  const backup = extend({}, events)
  // 滚动需要开启，额外判断
  if (config.enablePullDown) {
    if (backup.pullingDown) {
      addPullingDown(el, backup.pullingDown)
    }
  }
  delete backup.pullingDown
  // 绑定下拉刷新以外的事件
  Object.keys(backup).forEach(name => {
    const fn = backup[name]
    addEvent(el, name, fn)
  })
}
// 添加下拉事件
function addPullingDown(el, fn) {
  const newFn = async function (...args) {
    // 执行传入事件，捕获错误
    try {
      await fn.apply(this, args)
    } catch (error) {
      console.error(`${name} event error`, error)
    } finally {
      setTimeout(() => {
        // 下拉需要手动设置结束状态
        el.finishPullDown()
      }, 500)
    }
  }
  el.on('pullingDown', newFn)
}

function addEvent(el, name, fn) {
  const newFn = async function (...args) {
    // 执行传入事件，捕获错误
    try {
      await fn.apply(this, args)
    } catch (error) {
      console.error(`${name} event error`, error)
    }
  }
  el.on(name, newFn)
}

export default function useScroll(wrapperRef, options, emit) {
  const scroll = ref(null)

  const { config, contentIndex, events } = options

  onMounted(() => {
    // 浅拷贝
    const mergeConfig = normalizeConfig(config, contentIndex)
    const scrollVal = (scroll.value = new BScroll(wrapperRef.value, mergeConfig))

    bindEvents(config, events, scrollVal)

    if (config.probeType > 0) {
      scrollVal.on('scroll', position => {
        emit('scroll', position)
      })
    }
  })

  onUnmounted(() => {
    scroll.value.destroy()
  })

  onActivated(() => {
    // 使用 keep-alive 需要对 BScroll 进行额外操作，不然会出现奇怪现象
    scroll.value.enable()
    scroll.value.refresh()
  })

  onDeactivated(() => {
    scroll.value.disable()
  })

  return scroll
}
