<template>
  <div class="company-section">
    <div class="title" :style="titleBackground" @click="expendHandler">
      <CompanyRow
        className="no-padding "
        :name="name"
        :count="count"
        :totalInstallCount="totalInstallCount"
        :hasExpend="hasChildren"
        :isExpend="isExpend"
        :emphasize="emphasize"
      >
        <template #prefix v-if="typeof rank === 'number'">
          <div class="rank">
            <van-image v-if="imgUrl" :src="imgUrl" class="rank-img" />
            <div v-else class="content">{{ rank + 1 }}</div>
          </div>
        </template>
      </CompanyRow>
    </div>

    <Transition :css="false" @before-enter="onBeforeEnter" @enter="onEnter" @leave="onLeave">
      <div class="children" v-if="hasChildren && isExpend">
        <Company
          v-for="com in children"
          :key="com.companyId"
          :name="com.name"
          :count="com.count"
          :totalInstallCount="com.totalInstallCount"
          :children="com.children"
        />
      </div>
    </Transition>
  </div>
</template>

<script>
import { watch, computed, ref } from 'vue'
import { gsap } from 'gsap'

import goldenCrown from '@/assets/install/golden-crown.png'
import sliverCrown from '@/assets/install/sliver-crown.png'
import copperCrown from '@/assets/install/copper-crown.png'

import CompanyRow from './company-row.vue'

export default {
  components: { CompanyRow },
  props: {
    name: {
      type: String,
      required: true
    },
    titleBackground: {
      type: String
    },
    rank: {
      type: Number,
      default: null
    },
    emphasize: {
      type: Boolean
    },
    count: {
      type: Number,
      required: true
    },
    totalInstallCount: {
      type: Number,
      required: true
    },
    children: {
      type: Array
    }
  },
  setup(props) {
    const imgUrl = computed(() => {
      if (props.rank === 0) return goldenCrown
      if (props.rank === 1) return sliverCrown
      if (props.rank === 2) return copperCrown

      return ''
    })

    const hasChildren = computed(() => props.children.length > 0) // 是否有展开操作
    const isExpend = ref(false) // 展开状态
    watch(
      () => props.children,
      val => {
        isExpend.value = false
      },
      { immediate: true }
    )

    const expendHandler = e => {
      if (!hasChildren.value) {
        return
      }
      isExpend.value = !isExpend.value
    }
    function onBeforeEnter(el) {
      el.style.height = 0
    }
    // 用这个来开始进入动画
    function onEnter(el, done) {
      gsap.to(el, {
        duration: 0.3,
        height: '100%',
        ease: 'none',
        onComplete: done
      })
    }

    function onLeave(el, done) {
      gsap.to(el, {
        duration: 0.3,
        height: '0%',
        ease: 'none',
        onComplete: done
      })
    }

    return {
      imgUrl,
      hasChildren,
      isExpend,
      expendHandler,
      onBeforeEnter,
      onEnter,
      onLeave
    }
  }
}
</script>
<style lang="scss" scoped>
.company-section {
  .title {
    padding: 0 12px;
    .rank {
      font-size: 16px;
      color: #20284a;
      font-weight: 500;
      text-align: center;
      width: 24px;
      .rank-img {
        margin-right: 2px;
      }
    }
  }

  .children {
    padding-left: 12px;
    :deep(.company-section) {
      .title {
        border-bottom: 1px dashed #c7bdbd5c;
      }
    }
  }
}
</style>
