<template>
  <TitleBar ref="titleRef" @select="selectMonth" :titleDate="titleDate" :initIndex="initMonth" />
  <div ref="container" class="month-install">
    <div class="content position-relative">
      <div style="background-color: #fff">
        <div class="grid title">
          <div>厂区名称</div>
          <div ref="widthEl" class="blue text-center">续费</div>
          <div class="green text-center">到期</div>
        </div>
        <Company
          class="line companies"
          titleBackground="background-image: linear-gradient(90deg, #f3f8fda8 0%, #f2f8ff 100%);"
          v-for="(item, index) in renderTree"
          :key="item.id"
          :rank="index"
          :name="item.name"
          :count="item.count"
          :totalInstallCount="item.totalInstallCount"
          :children="item.children"
          emphasize
        />
        <div v-show="renderTree.length" class="text-center padding-8" style="color: #dcdee0">没有更多数据了</div>
      </div>
      <!-- 空提示 -->
      <van-empty v-if="!reqLoading && !renderTree.length" image="search" description="未查询到相关数据" />

      <div class="grid title position-absolute fixed-top w-100" v-show="showFake">
        <div>厂区名称</div>
        <div class="blue text-center">新增</div>
        <div class="green text-center">累计</div>
      </div>
    </div>
  </div>
</template>
<script>
import { watch, shallowRef, ref, nextTick } from 'vue'

import http from '@/utils/request'
import TitleBar from '../../components/title-bar.vue'

import Company from '../../components/company.vue'

export default {
  name: 'MonthCount',
  components: {
    TitleBar,
    Company
  },
  props: { showFake: { type: Boolean, default: false } },
  setup() {
    const currentIndex = ref(-1)
    // 渲染的树
    const renderTree = ref([])

    const reqLoading = ref(false)

    async function formatRenewData(params) {
      try {
        reqLoading.value = true
        let { data } = await http.get('renewal/v2/renewalNumStatistics.html', { params })
        renderTree.value = []
        renderTree.value = toTree(data)
        await nextTick()
      } finally {
        reqLoading.value = false
      }
    }

    const { titleDate, initMonth } = useTitle()
    const { container, widthEl, scrollHandler } = useScroll()

    const selectMonth = (index, data) => {
      if (currentIndex.value === index) return

      const [year, month] = data.split('-')
      currentIndex.value = index
      formatRenewData({ year, month })
    }

    const titleRef = ref()
    const init = () => {
      if (currentIndex.value === initMonth) {
        const [year, month] = titleDate.value[initMonth - 1].split('-')
        formatRenewData({ year, month })
      } else {
        titleRef.value.clickHandler(initMonth, titleDate.value[initMonth - 1])
      }
    }

    return {
      titleDate,
      initMonth,
      currentIndex,
      selectMonth,
      renderTree,
      container,
      widthEl,
      titleRef,
      scrollHandler,
      reqLoading,
      init
    }
  }
}
// 生成顶部标题区域
function useTitle() {
  const titleDate = ref([])
  const defaultMonth = Array(12)
    .fill(null)
    .map((_, i) => {
      let month
      if (i + 1 < 10) {
        month = `0${i + 1}`
      } else {
        month = `${i + 1}`
      }
      return month
    })

  const currentYear = new Date().getFullYear()

  const initMonth = new Date().getMonth() + 12

  titleDate.value = [currentYear - 1, currentYear].reduce((acc, cur) => {
    defaultMonth.forEach(mon => {
      acc.push(cur + '-' + mon)
    })
    return acc
  }, [])

  return {
    titleDate,
    initMonth
  }
}
// 滚动区域
function useScroll() {
  // 页面内容区
  const container = ref()
  // 绑定头部 title 标题元素，用来计算宽度
  const widthEl = shallowRef()
  // 滚动元素
  // 计算新增，累计对应的宽度
  watch(widthEl, el => {
    const width = el.clientWidth
    if (container.value) {
      container.value.style.setProperty('--common-width', width + 'px')
    }
  })

  return {
    container,
    widthEl
  }
}

function toTree(arr) {
  const tree = []
  // 公司 map
  const comMap = new Map()

  for (let com of arr) {
    // 可能先遍历到子级时新建父级公司信息
    // 进行判断，写入公司信息
    if (!comMap.has(com.id)) {
      comMap.set(com.id, { ...com, children: [] })
    }

    const current = comMap.get(com.id)
    current.name = com.name
    current.parentId = com.parentId
    current.id = com.id
    current.count = com.monthRenewalNum
    current.totalInstallCount = com.monthExpireNum
    // 顶级公司写入
    if (!current.parentId) {
      tree.push(current)
      continue
    }
    // 没有父级新建
    if (!comMap.has(com.parentId)) {
      comMap.set(com.parentId, { name: com.parentName, companyId: com.parentId, children: [] })
    }
    const parent = comMap.get(com.parentId)

    parent.children.push(current)
  }
  return tree
}
</script>
<style lang="scss" scoped>
.month-install {
  margin-top: 8px;
}
.content {
  height: 100%;
  overflow: hidden;
  font-size: 14px;
  color: #404867;
}

.title {
  padding: 11px 12px;
  align-items: center;
  font-size: 14px;
}
.grid {
  display: grid;
  grid-template-columns: 2.2fr 1fr 1fr;
}

.line {
  border-bottom: 1px solid;
  border-image: linear-gradient(0deg, #fff, #f2f8ff 40%, #00000014);
}
.line::after {
  background-color: #fff;
  box-shadow: inset 0 2px 4px #00000014;
}
.fixed-top {
  top: 0;
  background-color: #fff;
  box-sizing: border-box;
}

.affix {
  height: 32px;
  width: 32px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.padding-8 {
  padding: 8px;
}

.background-color {
  background-image: linear-gradient(90deg, #f3f8fda8 0%, #f2f8ff 100%);
}

.companies {
  background-color: #fff;
}
</style>
