import homeIconDefault from '@/assets/docker/home_default.png'
import homeIconActive from '@/assets/docker/home_active.png'
import locationIconDefault from '@/assets/docker/location_default.png'
import locationIconActive from '@/assets/docker/location_active.png'
import searchIconDefault from '@/assets/docker/searchCar_default.png'
import searchIconActive from '@/assets/docker/searchCar_active.png'
import settingIconDefault from '@/assets/docker/setting_default.png'
import settingIconActive from '@/assets/docker/setting_active.png'
import userIconDefault from '@/assets/docker/user_default.png'
import userIconActive from '@/assets/docker/user_active.png'

export default {
  homeIconDefault,
  homeIconActive,
  locationIconDefault,
  locationIconActive,
  searchIconDefault,
  searchIconActive,
  settingIconDefault,
  settingIconActive,
  userIconDefault,
  userIconActive
}
