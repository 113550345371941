<template>
  <NavBar :is-return="false"></NavBar>
  <div class="search-car container">
    <van-search v-model="searchContent" placeholder="搜索车牌号码(至少4位)" @update:model-value="onSearch" />
    <div class="search-car__details" id="scroll-wrap">
      <div
        class="search-car__details__item underline"
        v-for="(item, index) in carsInfo"
        :key="index"
        @click="getDetail(item)"
      >
        <div style="padding-left: 8px">
          <div class="company">
            公司名称：
            <span>{{ item.companyName }}</span>
          </div>

          <div>
            车牌号码：
            <span>{{ item.carNumber }}</span>
          </div>
        </div>
        <div
          class="search-car__details__item__status position-absolute"
          :style="item.runStatus === '1' ? 'color:#07c160' : 'color:#ee0a24'"
        >
          {{ item.runStatus === '1' ? '在线' : '离线' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onActivated } from 'vue'
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import { useStore } from 'vuex'
import { Toast } from 'vant'
import http from '@/utils/request'

export default {
  name: 'SearchCar',
  setup() {
    const store = useStore()
    const router = useRouter()
    const searchContent = ref('')
    const carsInfo = ref([])

    // 保存滚动位置
    onBeforeRouteLeave(() => {
      const position = document.querySelector('#scroll-wrap').scrollTop
      store.commit('updateSearScroll', position)
    })
    onActivated(() => {
      document.querySelector('#scroll-wrap').scrollTop = store.state.searScroll
      store.commit('changeNum/resetState')
      // 每次进入清除详情页保存的车辆信息
      localStorage.removeItem('tempSearch')
      onSearch(searchContent.value)
    })
    //搜索车辆
    const onSearch = val => {
      val = val.trim()
      val = val.toUpperCase()
      if (val.length > 3) {
        try {
          searchCar(val, carsInfo)
        } catch (error) {
          Toast('请求数据失败，请重试')
          return
        }
      } else {
        carsInfo.value = []
      }
    }
    const getDetail = item => {
      store.commit('search/storeCarInfo', {
        carNumber: item.carNumber,
        deviceId: item.deviceId,
        gpsUploadDate: item.gpsUploadDate,
        carUploadDate: item.carUploadDate,
        runStatus: item.runStatus
      })
      // 写入订单查询条件，默认查询当前车辆
      // 时间若为空，查询是会默认查询三个月内数据
      // 切换查看不同车辆时，需要将时间置空
      store.commit('search/resetOptions', {
        carNumber: item.carNumber,
        deviceId: item.deviceId
      })
      router.push({
        name: 'SearchResult',
        query: {
          title: item.carNumber
        }
      })
    }

    return {
      searchContent,
      onSearch,
      carsInfo,
      getDetail
    }
  }
}
async function searchCar(carNumber, carsInfo) {
  let result = await http.post('realtime/queryRealTimeListByCarNumber.html', {
    carNumber: carNumber
  })
  if (result.data.length === 0) {
    Toast('未查询到相关车辆信息')
  }
  carsInfo.value = result.data
  return result
}
</script>
<style lang="scss" scoped>
div {
  box-sizing: border-box;
}
.company {
  padding: 4px 0;
  font-size: 14px;
}
.search-car {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__details {
    margin-top: 8px;
    background-color: #fff;
    flex-grow: 1;
    overflow-y: auto;
    &__item {
      position: relative;
      padding: 8px 12px;
      &__status {
        right: 8px;
        top: 20px;
      }
    }
  }
}
</style>
