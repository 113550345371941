<template>
  <div
    :class="['dropdown-item line', 'flex-spb', { selected: parent.props.modelValue === value }]"
    @click.stop="clickHandler"
  >
    <div class="text">{{ text }}</div>
    <div class="icon" v-if="parent.props.modelValue === value">
      <van-icon name="success" />
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'
export default {
  props: {
    value: {
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const parent = inject('dropdown-parent')

    const clickHandler = () => {
      parent.updateValue(props.value, props.text)
    }

    if (parent.props.modelValue === props.value) {
      parent.updateValue(props.value, props.text)
    }

    parent.children.value.push({ clickHandler, index: parent.children.value.length, value: props.value })

    return {
      parent,
      clickHandler
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/style/variables.scss';
.dropdown-item {
  font-size: 14px;
  color: #20284a;
  padding: 16px 10px;
  &.selected {
    color: $blue;
  }
}
</style>
